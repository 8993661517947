import React from 'react';
import DocsLayout from '../../layouts/DocsLayout';

export default class PaymentView extends React.PureComponent {
  render() {
    return (
      <DocsLayout>
        <h5 className="font-weight-bold">Payment Links</h5>
        <div className="card border-0 shadow rounded-0 my-4">
          <div className="card-body">
            <p style={{letterSpacing: '0.1px'}}>E-merchant Payment Links is an Innovative way to accept payments directly from your facebook page, website or even simply through an Email, Chat or an SMS.</p>
            <br/>
            <p style={{letterSpacing: '0.1px'}}>You just have to generate and share the link with your customers, especially if you run your business mainly through your facebook page, Instagram or WhatsApp.</p>
            <br/>
            <p style={{letterSpacing: '0.1px'}}>Your customers can make the payment for your products or services just by clicking on the link.</p>
          </div>
        </div>
        <div className="card border-0 shadow my-1">
          <div className="card-body py-3">
            <p className="font-weight-bold">Accepting payments with Payment Links</p>
          </div>
        </div>
        <div className="card border-0 shadow mb-4">
          <div className="card-body">
            <p className="pb-2">It’s just 3 simple steps.</p>
            <ol className="ml-4">
              <p><li className="my-2">Generate a Link by adding an Item on your E-merchant Account</li></p>
              <p><li className="my-2">Copy the Payment Link generated for your item</li></p>
              <p><li className="my-2">Paste the Link in the caption of your facebook post, website or in the Email, Chat or SMS you send to your customer.</li></p>
            </ol>
            <p>These steps are quite straightforward & simple to follow. But to clarify you in detail, here are the specific detailed steps you need to follow to fulfill the above steps.</p>
          </div>
        </div>
        <div className="card border-0 shadow my-1">
          <div className="card-body py-3">
            <p className="font-weight-bold">1. Adding your Items to E-merchant Account</p>
          </div>
        </div>
        <div className="card border-0 shadow mb-4">
          <div className="card-body">
            <ol className="ml-4">
              <p><li className="my-2">Sign in to your E-merchant Account</li></p>
              <p><li className="my-2">Go to 'Pay Links’' tab</li></p>
              <p><li className="my-2">Fill the ‘Add Item’ form in the left by entering your item name, amount & description</li></p>
              <p><li className="my-2">Click ‘Generate Link’ button, and now you can see the Payment Link generated for your item.</li></p>
            </ol>
          </div>
        </div>
        <div className="card border-0 shadow my-1">
          <div className="card-body py-3">
            <p className="font-weight-bold">2. Copy the Payment Link of each item</p>
          </div>
        </div>
        <div className="card border-0 shadow mb-4">
          <div className="card-body">
            <ol className="ml-4">
              <p><li className="my-2">Find your Item from the list of Items</li></p>
              <p><li className="my-2">Click on ‘Copy Link’ below  your item</li></p>
            </ol>
          </div>
        </div>
        <div className="card border-0 shadow my-1">
          <div className="card-body py-3">
            <p className="font-weight-bold">3. Placing Payment Links on your facebook posts/website or send them through Email, Chat or SMS</p>
          </div>
        </div>
        <div className="card border-0 shadow mb-4">
          <div className="card-body">
            <ol className="ml-4">
              <p><li className="my-2">Go to your item’s facebook post, web page or compose an Email, Chat or SMS to your customer</li></p>
              <p><li className="my-2">Type some instruction on payment such as “Pay with E-merchant: “</li></p>
              <p><li className="my-2">Paste the copied Payment Link in front of that instruction</li></p>
            </ol>
            <p>That’s it. Now your customers can simply click on this link & pay you easily.</p>
          </div>
        </div>
      </DocsLayout>
    )
  }
}
