import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Container, Button, Image } from 'react-bootstrap';
import { ViewUsers } from '../../../../app/user/UserAction';
import { CipherLink, CreateTransfer } from '../../../../app/link/LinkAction';

import './index.css';
import Routes from '../../../../config/routes';

import Logo from '../../../../assets/images/logo.png';
import Office from '../../../../assets/images/office.png';

class TransactionPaymentView extends React.PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			data: {
				currency: 'USD',
				amount: '',
				title: 'Merchant Payment',
				description: 'Shared Link',
			},
			merchant: {},
			error: 200,
			loading: true,
			user: props.match.params.user,
			disable: false,
		};
	}

	async componentDidMount() {
		const query = new URLSearchParams(this.props.location.search);

		await this.props.ListUser(`EMC${this.state.user}`);
		await this.props.CipherLink(query.get('q'));

		this.setState({ loading: false });
	}

	componentWillReceiveProps = next => {
		if (next) {
			this.setState({ merchant: next.users.data, error: next.users.status });

			if (next.link?.link) {
				const link = JSON.parse(next.link?.link);
				const currency = link.currency  !== 'USD' && link.currency !== 'EUR' ? 'RWF' : link.currency;
				const disable = link.amount > 0 ? true : false;

				this.setState({
					data: {
						...this.state.data,
						currency,
						amount: link.amount,
						title: link.description,
					},
					disable
				});
			}
		}
	};

	handleCurrency = currency => {
		const { data } = this.state;
		this.setState({ data: { ...data, currency } });
	};

	handleLogin = () => {
		this.props.history.push(Routes.account.login);
	};

	handleSubmit = async () => {
		const { data } = this.state;

		if (data.amount === '') {
			alert('Please input amount.');
			return;
		}

		await this.props.CreateTransfer(this.state.merchant?.id, this.state.data);
	};

	render() {
		const { loading, data, disable, error } = this.state;

		return (
			<div className='mobile-view payment-mobile'>
				<div className='header'>
					<Container>
						<div className='d-flex justify-content-between py-4'>
							<div>
								<Link to={Routes.client.home}><Image src={Logo} width='75px' className='img-fluid' /></Link>
							</div>
							<div>
								<Button variant='outline-primary' onClick={this.handleLogin}>
									Log In
								</Button>
							</div>
						</div>
					</Container>
				</div>

				{loading ? (
					<div className='text-center'>
						<div className='desc'>
							<p className='py-5 my-5 text-medium color-black'>Loading...</p>
						</div>
					</div>
				) : error === 200 ? (
					<>
						<div className='profile text-center'>
							<div className='image-info'>
								<Image src={Office} className='user img-fluid' />
								<Image
									src='https://cdn.emc.xyz/images/flag/circle/rwanda.png'
									className='flag img-fluid'
								/>
							</div>

							<div className='desc'>
								<p className='mt-4 text-medium color-black'>
									{this.state.merchant?.business}
								</p>
								<p className='text-underline color-blue-dark'>
									emc.xyz/{this.state.user}
								</p>
							</div>
						</div>

						<div className='form'>
							<Container>
								<div className='d-flex justify-content-center align-items-center w-100'>
									<span>
										{data.currency === 'USD' && '$'}
										{data.currency === 'EUR' && '€'}
										{data.currency === 'RWF' && 'RF'}
									</span>

									{
										disable ? <p className='amount-fixed'>{data.amount}</p> : <input
										type='number'
										className='form-control'
										placeholder='10.00'
										autoFocus
										min='1'
										value={data.amount}
										disabled={disable}
										onChange={({ target }) =>
											this.setState({ data: { ...data, amount: target.value } })
										}
									/>
									}
								</div>

								<div className='d-flex justify-content-center align-items-center w-100'>
									{
										disable ? data.currency : <div class='dropdown'>
										<button
											class='btn btn-default dropdown-toggle'
											type='button'
											id='dropdownMenuButton'
											data-toggle='dropdown'
											aria-haspopup='true'
											aria-expanded='false'
										>
											{data.currency}
										</button>
										<div
											class='dropdown-menu'
											aria-labelledby='dropdownMenuButton'
										>
											<a class='dropdown-item cursor' onClick={() => this.handleCurrency('USD')}>
												USD
											</a>
											<a class='dropdown-item cursor' onClick={() => this.handleCurrency('EUR')}>
												EUR
											</a>
											<a class='dropdown-item cursor' onClick={() => this.handleCurrency('RWF')}>
												RWF
											</a>
										</div>
									</div>
									}
								</div>

								<div className='d-flex justify-content-center align-items-center w-100'>
									<Button
										variant='primary'
										className='mt-4'
										onClick={this.handleSubmit}
									>
										Next
									</Button>
								</div>
							</Container>
						</div>
					</>
				) : (
					<div className='text-center'>
						<div className='desc'>
							<p className='py-5 my-5 text-medium color-black'>
								Merchant Not Found
							</p>
						</div>
					</div>
				)}
			</div>
		);
	}
}

const mapStateToProps = state => ({
	users: state.UserReducer.users,
	link: state.LinkReducer.link,
});

const mapDispatchToProps = dispatch => ({
	ListUser: id => dispatch(ViewUsers(id)),
	CipherLink: link => dispatch(CipherLink({ link })),
	CreateTransfer: (id, payload) => dispatch(CreateTransfer(id, payload)),
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(TransactionPaymentView);
