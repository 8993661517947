import React from 'react';
import './index.css';

export default class AuthLayout extends React.PureComponent {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    const { children } = this.props;

    return (
      <div className="auth-layout">
        {children}
      </div>
    );
  }
}
