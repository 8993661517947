import { reverse } from 'named-urls';

class util {
	string(value, character) {
		return value.split(character);
	}

	copyText(value) {
		var textField = document.createElement('textarea');

		textField.innerText = value;

		document.body.appendChild(textField);
		textField.select();

		document.execCommand('copy');
		textField.remove();
	}

	contains(array, value) {
		return array.some(v => v === value);
	}

	isArray(array) {
		return Array.isArray(array);
	}

	route(path, params = []) {
		return reverse(path, params);
	}

	renderError(obj) {
		return typeof obj.detail === 'string' || obj.detail instanceof String
			? obj.detail
			: `${obj.detail[0].loc[1]} ${obj.detail[0].msg}`;
	}
}

export default new util();
