import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import App from './App';
import Store from './app/Store';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-phone-input-2/lib/style.css';

import './assets/fonts/poppins/style.css';
import './assets/fonts/montserrat/style.css';
import './assets/fonts/style.css';
import './assets/css/spinners.css';
import './assets/css/normalize.css';
import './assets/css/global.css';

ReactDOM.render(
	<Provider store={Store}>
		<App />
	</Provider>,

	// <React.StrictMode>
	// 	<Provider store={Store}>
	// 		<App />
	// 	</Provider>
	// </React.StrictMode>,
	document.getElementById('root')
);
