import React from 'react';
import DocsLayout from '../../layouts/DocsLayout';

export default class CheckoutView extends React.PureComponent {
  render() {
    return (
      <DocsLayout>
        <h5 className="font-weight-bold">Checkout API</h5>
        <div className="card border-0 shadow rounded-0 my-4">
          <div className="card-body">
            <p style={{letterSpacing: '0.1px'}}>E-merchant Checkout API lets you integrate an E-merchant Card  with your website, web application or any other application in code level. It offers a simple HTML Form based POST API to initiate a payment request and redirect your customer to Payment Gateway to securely process the payment. </p>
            <br/>
            <p style={{letterSpacing: '0.1px'}}>Once the payment is processed it notifies your given URL (notify_url) about the payment status by a server callback with a checksum to verify the response params. You can use those response params & checksum to verify and update your system, based on the payment status.</p>
          </div>
        </div>
        <div className="card border-0 shadow my-1">
          <div className="card-body py-3">
            <p className="space-0 font-weight-bold">Prerequisites</p>
          </div>
        </div>
        <div className="card border-0 shadow mb-4">
          <div className="card-body">
            <p className="pb-2">You need your Merchant ID & Merchant Secret to integrate with Checkout API.</p>
            <ul className="ml-5">
              <p><li className="my-2">Merchant ID can be found at Settings &gt; <span className="text-active"> Domains & Credentials</span></li></p>
              <p>
                <li className="my-2">Merchant Secret can be generated for your domain/app as follows</li>
                <ol className="ml-5">
                  <p><li className="my-2">Go to Settings &gt; <span className="text-active"> Domains & Credentials</span></li></p>
                  <p><li className="my-2">Click 'Add Domain/App' &gt; Fill details &gt; Click 'Request to Allow'</li></p>
                  <p><li className="my-2">Wait for the approval for your domain</li></p>
                  <p><li className="my-2">Copy the Merchant Secret for your domain/app</li></p>
                </ol>
              </p>
            </ul>
            <p>Note: Merchant Secrets are domain/app specific, therefore you need to generate a new Merchant Secret every time you're integrating E-merchant Card on a new domain/app.</p>
          </div>
        </div>
      </DocsLayout>
    )
  }
}