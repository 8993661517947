import React from "react";
import { Link } from "react-router-dom";
import { Container, Button, Image, Form } from "react-bootstrap";
import Routes from '../../../config/routes';
import Util from '../../../core/helpers/Util';
import Avatar from '../../../assets/images/avatar.jpg';
import './index.css';
import helper from '../../../core/helpers/helper';
import { connect } from "react-redux";
import { AuthRevoke, SwithcMode } from '../../../app/user/UserAction';

class SideBar extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      account: {}
    }
  }

  handleCloseSidebar = () => {
    const { handleSidebar } = this.props;
    handleSidebar(false);
  }

  handleSwitchMode = async () => {
		const { account } = this.state;
		const mode = account?.mode === 'live' ? false : true;

		this.setState({ account: { ...account, mode } });

		await this.props.SwitchAction();
	};

	handlechange = ({ target }) => {
		this.setState({
			data: { ...this.state.data, [target.name]: target.value },
		});
	};

  handleAuthRevoke = async () => {
		const { AuthRevoke } = this.props;
		await AuthRevoke();

		return (window.location.href = Routes.account.login);
	};

  render() {
    const { mobileSidebar, account } = this.props;

    return (
      <div className='sidebar-content'>
        {
          mobileSidebar && <Container className='sidebar-profile mb-4'>
            <div class="dropdown">
            <a class='font-regular nav-link dropdown-toggle'  href="#" id="navbarDropdownMenuLink" role="button" data-toggle="dropdown">
              <Image src={Avatar} className='navbar-avatar img-fluid' alt='Full Name' />
              <small className='navbar-user'>
                { account?.business }
              </small>
            </a>
            <div class="dropdown-menu border-0 shadow" aria-labelledby="navbarDropdownMenuLink">
              <Link class="dropdown-item" to={Routes.account.profile}><small className="font-bold space-0 text-muted"> Profile</small></Link>
              <a class="dropdown-item" href="#" onClick={this.handleAuthRevoke}><small className="font-bold space-0 text-muted"> Sign out</small></a>
            </div>
          </div>

            <Button variant='default' className='navbar-close-collapse' onClick={this.handleCloseSidebar}>
              <i className='fas fa-times'></i>
            </Button>
          </Container>
        }

        <div className='sidebar-menu-box'>
          <ul className="pt-md-5">
            <li>
              <Link to={Util.route(Routes.account.dashboard)} className={helper.activeMenu(Routes.account.dashboard)}>
                <span className='sidebar-menu-icon'><i className='fas fa-home'></i></span> Dashboard
              </Link>
            </li>

            <li>
              <Link to={Util.route(Routes.account.transaction)} className={helper.activeMenu(Routes.account.transaction)}>
                <span className='sidebar-menu-icon'><i className='fas fa-history'></i></span> Transactions
              </Link>
            </li>

            <li>
              <Link to={Util.route(Routes.account.links)} className={helper.activeMenu(Routes.account.links)}>
                <span className='sidebar-menu-icon'><i className='fas fa-link'></i></span> Pay Links
              </Link>
            </li>

            <li>
              <Link to={Util.route(Routes.account.settings)}>
                <span className='sidebar-menu-icon'><i className='fas fa-key'></i></span> API Keys
              </Link>
            </li>
            
            {/* <li>
              <Link to={Util.route(Routes.dashboard)}>
                <span className='sidebar-menu-icon'><i className='fas fa-wallet'></i></span>  Checkout API
              </Link>
            </li>
            
            <li>
              <Link to={Util.route(Routes.dashboard)}>
                <span className='sidebar-menu-icon'><i className='fas fa-wallet'></i></span>  Checkout API
              </Link>
            </li> */}
          </ul>
        </div>

        <div className='sidebar-merchant px-4 mx-2'>
          <Form.Group>
            {/* <Form.Label>API Live Mode</Form.Label> */}
            <Form.Check
              type='switch'
              id='custom-switch'
              label={`Switch to ${
                account?.mode === 'live' ? 'test' : 'live'
              } mode`}
              className='mb-4'
              checked={account?.mode === 'live' ? true : false}
              onChange={this.handleSwitchMode}
            />
          </Form.Group>
          
          <div className='sidebar-merchant-box bg-grey px-3 py-2 color-black'>
            <p><small className='font-bold text-muted'>Merchant ID </small> <span className='float-right badge bg-info py-1 px-2 color-white'>EMC</span></p>
            <h6 className='font-bold text-primary'>{account?.merchant}</h6>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
	account: state.UserReducer.account,
});

const mapDispatchToProps = dispatch => ({
	AuthRevoke: () => dispatch(AuthRevoke()),
  SwitchAction: () => dispatch(SwithcMode()),
});

export default connect(mapStateToProps, mapDispatchToProps)(SideBar);
