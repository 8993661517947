import React from 'react';
import Navbar from '../component/navbar';
import Mobile from '../../../assets/images/mobile-money 1.svg';
import Hands from '../../../assets/images/paypal 1.svg';
import Space from '../../../assets/images/visa 1.svg';
import Piggy from '../../../assets/images/mastercard 1.svg';
import PlayStore from '../../../assets/images/google-play.svg';
import AppStore from '../../../assets/images/app-store-btn.svg';
import App from '../../../assets/images/EMC Mobile.svg';
import Transfer from '../../../assets/images/wordpress.png';
import Currency from '../../../assets/images/api.png';
import Links from '../../../assets/images/link.png';
import Send from '../../../assets/images/shopify.png';
import Verified from '../../../assets/images/facebook.svg';
import Master from '../../../assets/images/instagram.svg';
import AWS from '../../../assets/images/whatsapp.svg';
import PCI from '../../../assets/images/gmail.svg';
import { Link } from 'react-router-dom'
import './index.css'
import Routes from '../../../config/routes';

const MerchantView = () => {
  return ( 
    <>
			<Navbar />

			<main>
				<section className='bg-image2 text-white'>
					<div className='container'>
						<div className='d-flex flex-column justify-content-center align-items-center'>
							<h1 className='font-weight-bold'>Get Paid Online</h1>
							<h5 className='font-weight-light d-none d-md-block' style={{ fontWeight: '100' }}>
								Quickly. Securely. Globally.
							</h5>
							<h4 className='d-md-none d-block' style={{ fontWeight: '100' }}>
								Quickly. Securely. Globally.
							</h4>
						</div>
						<div className='row justify-content-center align-items-end mt-5'>
							<div className='col-md-6'>
								<div class='input-group mb-3'>
									<select class='custom-select' defaultChecked="0" id='inputGroupSelect02'>
										<option value="0">Choose your country to try</option>
										<option value='1'>Rwanda</option>
										<option value='2'>Tanzania</option>
										<option value='3'>Gabon</option>
										<option value='4'>Nigeria</option>
										<option value='5'>Senegal</option>
										<option value='6'>Benin</option>
										<option value='7'>Burundi</option>
										<option value='8'>Togo</option>
										<option value='9'>Burkina Faso</option>
									</select>
									<button class='input-group-append border-0 primary-bg'>
										<label
											class='border-0 input-group-text text-white mt-1'
											for='inputGroupSelect02'
										>
											Get Started
										</label>
									</button>
								</div>
							</div>
						</div>
					</div>
				</section>
				<div className='container py-5 px-lg-5'>
					<h4 className='text-center pb-4 d-none d-md-block'>
						Why choose E-merchant card ?
					</h4>
					<h5 className='text-center pb-4 d-block d-md-none'>
						Why choose E-merchant card ?
					</h5>
					<div className='row pt-4 mx-lg-5 px-lg-5'>
						<div className='col-sm-6 my-2'>
							<div className='media'>
								<img src={Space} className='img-fluid' />
								<div className='media-body ml-3'>
									<h6 className='my-0 py-0'>Accept VISA</h6>
									<p>
										<small className='text-muted my-0 py-0'>
											Collect payments from your customers worldwide through
											Visa Cards.
										</small>
									</p>
								</div>
							</div>
						</div>
						<div className='col-sm-6 my-2'>
							<div className='media'>
								<img src={Piggy} className='img-fluid' />
								<div className='media-body ml-3'>
									<h6 className='my-0 py-0'>Accept MasterCard</h6>
									<p>
										<small className='text-muted my-0 py-0'>
											Send & Collect payments worldwide through Master Cards.
										</small>
									</p>
								</div>
							</div>
						</div>
						<div className='col-sm-6 my-2'>
							<div className='media'>
								<img src={Mobile} className='img-fluid' />
								<div className='media-body ml-3'>
									<h6 className=' my-0 py-0'>Accept Mobile Money</h6>
									<p>
										<small className='text-muted my-0 py-0'>
											With Mobile Money, Pay & Get paid across 18 countries.
										</small>
									</p>
								</div>
							</div>
						</div>
						<div className='col-sm-6 my-2'>
							<div className='media'>
								<img src={Hands} className='img-fluid' />
								<div className='media-body ml-3'>
									<h6 className='my-0 py-0'>Accept Paypal</h6>
									<p>
										<small className='text-muted my-0 py-0'>
											Accept Paypal payments anywhere in Africa, without an
											account.
										</small>
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
				<section className='bg-light'>
					<div className='container py-5'>
						<div className='row justify-content-center align-items-center'>
							<div className='col-md-4'>
								<img src={App} className='img-fluid' />
							</div>
							<div className='col-md-5'>
								<h4
									className='font-weight-bold'
									style={{ lineHeight: '1.6em' }}
								>
									Check your balance & Manage your transactions with our App
								</h4>
								<div className='d-flex justify-content-center mt-4'>
									<img src={PlayStore} className='img-fluid mx-2' />
									<img src={AppStore} className='img-fluid mx-2' />
								</div>
							</div>
						</div>
					</div>
				</section>
				<section>
					<div className='container py-5 text-center'>
						<h4>A wide choice of ways to collect money</h4>
						<p>
							<small className='text-muted'>
								E-merchant is packed with features that can help you collect
								money fast and effortlessly.
							</small>
						</p>
						<div className='row pt-5'>
							<div className='col-md-3 my-2'>
								<div className='card shadow border-0'>
									<div className='card-body text-center'>
										<Link to={Routes.merchant.payment}>
											<img src={Links} className='img-fluid' />
											<h6 className='mt-3 font-weight-bold'>Pay Links</h6>
										</Link>
										<p>
											<small className='text-muted'>
												An Innovative way to accept payments directly from your
												facebook page, website or even simply through an Email,
												Chat or an SMS.
											</small>
										</p>
									</div>
								</div>
							</div>
							<div className='col-md-3 my-2'>
								<div className='card shadow border-0'>
									<div className='card-body text-center'>
										<Link to={Routes.merchant.checkout} href='#'>
											<img src={Currency} className='img-fluid' />
											<h6 className='mt-3 font-weight-bold'>Checkout API</h6>
										</Link>
										<p>
											<small className='text-muted'>
												E-merchant Checkout API lets you integrate with your
												website, web application or any other application in
												code level.
												{/* It offers a simple HTML Form based POST
												API to initiate a payment request and redirect your
												customer to PayHere Payment Gateway to securely process
												the payment. */}
											</small>
										</p>
									</div>
								</div>
							</div>
							<div className='col-md-3 my-2'>
								<div className='card shadow border-0'>
									<div className='card-body text-center'>
										<Link to={Routes.merchant.wordpress}>
											<img src={Transfer} className='img-fluid' />
											<h6 className='mt-3 font-weight-bold'>WordPress</h6>
										</Link>
										<p>
											<small className='text-muted'>
												Integrating e-merchant with your Wordpress WooCommerce
												website is super easy with our e-merchant Plugin for
												WooCommerce.
											</small>
										</p>
									</div>
								</div>
							</div>
							<div className='col-md-3 my-2'>
								<div className='card shadow border-0'>
									<div className='card-body text-center'>
										<Link to={Routes.merchant.shopify}>
											<img src={Send} className='img-fluid' />
											<h6 className='mt-3 font-weight-bold'>Shopify</h6>
										</Link>
										<p>
											<small className='text-muted'>
												Integrating e-merchant with your Shopify shop is super
												easy with our inbuilt plugin for Shopify you only do a
												single click.
											</small>
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
				<div className='img-bg text-center text-white'>
					<h3>Sell Your Products & Services across Africa</h3>
					<p className='mt-2'>
						& Receive Your payments with the E-merchand Card
					</p>
				</div>
				<div className='container py-5'>
					<div className='row align-items-center'>
						<div className='col-md-5 mt-4'>
							<p className='text-muted my-0'>
								<small>NEVER MISS A PAYMENT</small>
							</p>
							<h5 className='font-weight-bold'>
								No website? We got you covered.
							</h5>
							<p className='mt-5 text-muted'>
								Share a payment link on your social accounts, on WhatsApp, in an
								email, via SMS or anywhere on the internet and get paid fast.
							</p>
						</div>
						<div className='col-md-6 offset-md-1'>
							<div className='row'>
								<div className='col-md-6 text-center my-2'>
									<div className='card border-0 shadow'>
										<div className='card-body py-4'>
											<img
												src={Verified}
												width='60'
												className='img-fluid my-3'
											/>
										</div>
									</div>
								</div>
								<div className='col-md-6 text-center my-2'>
									<div className='card border-0 shadow'>
										<div className='card-body py-4'>
											<img src={Master} width='60' className='img-fluid my-3' />
										</div>
									</div>
								</div>
								<div className='col-md-6 text-center my-2'>
									<div className='card border-0 shadow'>
										<div className='card-body py-4'>
											<img src={AWS} width='60' className='img-fluid my-3' />
										</div>
									</div>
								</div>
								<div className='col-md-6 text-center my-2'>
									<div className='card border-0 shadow'>
										<div className='card-body py-4'>
											<img src={PCI} width='60' className='img-fluid my-3' />
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</main>
		</>
  );
}
 
export default MerchantView;