import React from 'react';
import UserLayout from '../../../layouts/UserLayout';
import { Row, Col, Form } from 'react-bootstrap';
import { connect } from 'react-redux';
import { SwithcMode, updateAccount } from '../../../../app/user/UserAction';

class Settingsview extends React.PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			account: {},
		};
	}

	componentWillReceiveProps({ account }) {
		this.setState({
			account,
		});
	}

	handleSwitchMode = async () => {
		const { account } = this.state;
		const mode = account?.mode === 'live' ? false : true;

		this.setState({ account: { ...account, mode } });

		await this.props.SwitchAction();
	};

	render() {
		const { account, data } = this.state;

		return (
			<UserLayout>
				<Row>
					<Col lg={6} className='mb-5'>
						<p className='text-normal font-medium'>API Keys</p>
						<hr />

						<Form.Group>
							<Form.Label>API Secret Key</Form.Label>
							<Form.Control type='text' readOnly value={account?.secret_key} />
						</Form.Group>

						<Form.Group>
							<Form.Label>Merchant Link</Form.Label>
							<Form.Control
								type='text'
								readOnly
								value={`${process.env.REACt_APP_URL}/${account?.merchant?.slice(
									3
								)}`}
							/>
						</Form.Group>

						<Form.Group>
							<Form.Label>API Live Mode</Form.Label>
							<Form.Check
								type='switch'
								id='custom-switch'
								label={`Switch to ${
									account?.mode === 'live' ? 'test' : 'live'
								} mode`}
								className='mt-2'
								checked={account?.mode === 'live' ? true : false}
								onChange={this.handleSwitchMode}
							/>
						</Form.Group>
					</Col>
				</Row>
			</UserLayout>
		);
	}
}

const mapStateToProps = state => ({
	account: state.UserReducer.account,
});

const mapDispatchToProps = dispatch => ({
	SwitchAction: () => dispatch(SwithcMode()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Settingsview);
