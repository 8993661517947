import { include } from 'named-urls';

const Routes = {
	client: include('/', {
		home: '',
		payment: '/:user',
		merchant: '/app/merchant',
		works: '/app/how-it-works',
	}),

	account: include('/account', {
		dashboard: 'dashboard',
		transaction: 'transactions',
		links: 'links',
		settings: 'api/keys',
		profile: 'profile',
		login: 'login',
		signup: 'signup',
		password: include('password', {
			forgot: 'forgot',
		}),
	}),

	merchant: include('/merchant', {
		payment: 'payment',
		checkout: 'checkout',
		wordpress: 'wordpress',
		shopify: 'shopify',
	}),
};

export default Routes;
