import Http from '../../core/client/Http';
import {
	LIST_LINK,
	CREATE_TRANSFER,
	CREATE_LINK,
	DELETE_LINK,
	CIPHER_LINK,
} from './LinkType';

export function CipherLink(link) {
	return async dispatch => {
		console.log(link);
		const { data } = await Http.post(
			'/merchant/link/cipher?action=decrypt',
			link
		);

		return dispatch({
			type: CIPHER_LINK,
			payload: data,
		});
	};
}

export function ListLink() {
	return async dispatch => {
		const { data } = await Http.get('/merchant/link');

		return dispatch({
			type: LIST_LINK,
			payload: data,
		});
	};
}

export function CreateLink(payload) {
	return async dispatch => {
		const { data, status } = await Http.post('/merchant/link/payment', payload);

		if (status === 201) {
			return dispatch({
				type: CREATE_LINK,
				payload: data,
			});
		}
	};
}

export function CreateTransfer(merchant, payload) {
	return async () => {
		const { data, status } = await Http.post(
			`/merchant/link/transfer?merchant=${merchant}`,
			payload
		);
		if (status === 201) {
			window.location.href = `${process.env.REACT_APP_PAYMENT}/${
				data.id
			}?method=${payload.method ?? 'card'}`;
		}
	};
}

export function DeleteLink(id) {
	return async dispatch => {
		const { data, status } = await Http.delete(`/merchant/link/${id}`);
		if (status === 204) {
			return dispatch({
				type: DELETE_LINK,
				payload: data,
			});
		}
	};
}
