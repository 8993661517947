import React from 'react';
import moment from 'moment';
import {Link } from 'react-router-dom';
import { connect } from 'react-redux';
import UserLayout from '../../../layouts/UserLayout';
import EmcCard from '../../../../assets/images/emc-card.svg';
import Rwanda from '../../../../assets/images/rwf.svg';
import USA from '../../../../assets/images/usd.svg';
import Europe from '../../../../assets/images/eur.svg';
import Check from '../../../../assets/images/check.svg';
import Cancel from '../../../../assets/images/cancel.svg';
import Arrow from '../../../../assets/images/arrow-right.svg';
import Routes from '../../../../config/routes';

import { WalletTramsaction, LatestTramsaction } from '../../../../app/transaction/TransactionAction';

class DashboardView extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      wallet: {
        usd: 0,
        eur: 0,
        local: 0
      },
      latest: [],
    }
  }

  async componentDidMount() {
    const { WalletAction, LatestAction } = this.props;

    await WalletAction()
    await LatestAction()
  }

  componentWillReceiveProps({ wallet, latest }) {
    this.setState({ wallet, latest });
  }

  render() {
    const { wallet, latest } = this.state;

    return (  
      <UserLayout>
        <div className="row">
          <div className="col-md-4 my-2">
            <img src={EmcCard} className="img-fluid" alt=""/>
          </div>
          <div className="col-md-7 offset-md-1 my-2">
            <div className="row">
              <div className="col-md-6 mb-3">
                <div className="card border-0 shadow">
                  <div className="card-body py-3">
                    <p><small className="font-weight-bold text-muted">Wallet</small></p>
                    <div className="d-flex justify-content-between align-items-center my-0 mt-md-1">
                      <h6 className="font-bold text-primary">USD {wallet.usd}</h6>
                      <img src={USA} alt=""/>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 mb-3">
                <div className="card border-0 shadow">
                  <div className="card-body py-3">
                    <p><small className="font-weight-bold text-muted">Wallet</small></p>
                    <div className="d-flex justify-content-between align-items-center my-0 mt-md-1">
                      <h6 className="font-bold text-primary">EUR {wallet.eur}</h6>
                      <img src={Europe} alt=""/>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 mb-3">
                <div className="card border-0 shadow">
                  <div className="card-body py-3">
                    <p><small className="font-weight-bold text-muted">Wallet</small></p>
                    <div className="d-flex justify-content-between align-items-center my-0 mt-md-1">
                      <h6 className="font-bold text-primary">RWF {wallet.local}</h6>
                      <img src={Rwanda} alt=""/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <h6 className="font-weight-bold mt-2">Recent Transaction</h6>
        <div className="table-responsive">
          <table class="table table-borderless">
            <thead>
              <tr className="">
                <th scope='col'>#</th>
                <th scope='col'>Name</th>
                <th scope='col'>Type</th>
                <th scope='col'>Mode</th>
                <th scope='col'>Amount</th>
                <th scope='col'>Date / Time</th>
              </tr>
            </thead>

            <tbody className="bg-light shadow-sm">
              {latest.map((value, key) => (
									<tr className='table-light shadow-sm'>
										<td scope='row'>{key + 1}</td>
										<td>{
												value.type === 'in' ? value.payload.name : value.payload.description
											}</td>
										<td scope='row'>
											{
												value.type === 'in' ? 'Received' : 'Sent'
											}
										</td>
										<td>MoMo Rwanda</td>
										<td>
											{
												value.type === 'in' ? <span className='text-success'>
												+ {`${value.payload.amount} ${value.payload.currency}`}
											</span> : <span className='text-danger'>
												- {`${value.payload.amount} ${value.payload.currency}`}
											</span>
											}
										</td>
										<td>{moment(value.created_at).format('MMMM Do HH:mm')}</td>
									</tr>
								))}
            </tbody>
          </table>
        </div>
        <div className="float-right mb-5">
          <div className="card border-0 shadow">
            <div className="card-body py-2">
              <Link to={Routes.account.transaction} className="d-flex justify-content-between align-items-center">
                <h6 className="mb-0 mr-2"><small className="font-weight-bold text-primary"> View more</small></h6>
                <img src={Arrow} className="img-fluid mt-1" alt=""/>
              </Link>
            </div>
          </div>
        </div>
      </UserLayout>
    );
  }
}

const mapsStateToProps = state => ({
  wallet: state.TransactionReducer.wallet,
  latest: state.TransactionReducer.latest,
});

const mapDispatchToProps = dispatch => ({
	WalletAction: () => dispatch(WalletTramsaction()),
	LatestAction: () => dispatch(LatestTramsaction()),
});

export default connect(mapsStateToProps, mapDispatchToProps)(DashboardView);
