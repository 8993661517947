import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import AuthLayout from '../../../layouts/AuthLayout';
import Logo from '../../../../assets/images/logo.jpg';
import Routes from '../../../../config/routes';
import PhoneInput from 'react-phone-input-2';
import { CreateAccount } from '../../../../app/user/UserAction';
import Navbar from '../../component/navbar';
import { Modal, Button, Form } from 'react-bootstrap';
import Util from '../../../../core/helpers/Util';

import Http from '../../../../core/client/Http';

class SignUpView extends React.PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			show: false,
			error: false,
			loading: false,
			data: {
				phone: '250780455791',
				business: '',
				country: '',
				password: '',
			},
			payload: {},
			otpShow: this.props.otpShow,
			otp: ''
		};
	}

	componentWillReceiveProps(next) {
		if (next) {
			console.log(next);

			const { error, payload, otpShow } = next;
			this.setState({ error, payload, otpShow });
		}
	}

	handleSubmit = async e => {
		e.preventDefault();

		this.setState({ loading: true });

		const { data } = this.state;
		const { CreateAccount } = this.props;

		await CreateAccount(data);

		this.setState({ loading: false });
	};

	handleChange = ({ target }) => {
		const { data } = this.state;
		this.setState({ data: { ...data, [target.name]: target.value } });
	};

	handleOTP = async e => {
		e.preventDefault();

		const { data, status } = await Http.post('/user/auth/otp/verify', {
			phone: this.state.data.phone,
			otp: this.state.otp,
		});

		if (status === 200) {
			localStorage.setItem('access_token', data.access);
			return window.location.reload();
		}

		alert('Incorect OTP');
		return;
	};

	render() {
		const { data, show, otpShow, error, payload, loading } = this.state;

		return (
			<>
				<Navbar theme={true} />
				<div className='bg-white shadow' />
				<div style={{ backgroundColor: '#EAECEF' }}>
					<div className='container py-5'>
						<div className='row justify-content-center my-4'>
							<div className='col-md-6 px-md-5 loginForm'>
								<div className='card border-0 shadow-sm'>
									<div className='card-body'>
										<h3 className='font-weight-bold text-center'>
											Create a merchant account, <br /> it’s free!
										</h3>
										<form onSubmit={this.handleSubmit} className='mt-5'>
											<div className='form-group my-3'>
												<label htmlFor='email'>
													<b className='loginForm'> Mobile Phone</b>
												</label>

												<PhoneInput
													regions={'africa'}
													country={'rw'}
													value={data.phone}
													onChange={(phone, country) =>
														this.setState({
															data: {
																...data,
																phone,
																country: country.countryCode.toUpperCase(),
															},
														})
													}
													inputClass='w-100 f-13 py-1'
													disabled={loading}
												/>
											</div>

											<div className='form-group'>
												<label htmlFor='email'>
													<b className='loginForm'> Business name</b>
												</label>

												<input
													name='business'
													type='text'
													className='form-control'
													placeholder='Ex: XYZ'
													value={data.business}
													onChange={this.handleChange}
													required
													readOnly={loading}
												/>
											</div>

											<label htmlFor=''>
												<b> 4-Digit Pin</b>
											</label>
											<div className='input-group mb-1'>
												<input
													type={show ? 'text' : 'password'}
													className='form-control border-right-0'
													placeholder='****'
													value={data.password}
													name='password'
													onChange={this.handleChange}
													required
													minLength='4'
													maxLength='4'
													readOnly={loading}
												/>

												<div className='input-group-append'>
													<span
														onClick={() => this.setState({ show: !show })}
														className='input-group-text border-left-0'
														id='basic-addon2'
													>
														{show ? (
															<i className='fas fa-eye-slash'></i>
														) : (
															<i className='fas fa-eye'></i>
														)}
													</span>
												</div>
											</div>
											<div className='media m-2'>
												{error && <div>Error</div>}
												<div className='media-body ml-2'>
													{error && (
														<span className='text-danger'>
															{Util.renderError(payload)}
														</span>
													)}
												</div>
											</div>
											<div className='pt-1'>
												<button className='btn btn-primary btn-block py-2 my-3'>
													<b>Sign Up</b>
												</button>
											</div>
										</form>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<Modal show={otpShow}>
					<Modal.Header>
						<Modal.Title>Verify OTP</Modal.Title>
					</Modal.Header>
					<Form onSubmit={this.handleOTP}>
						<Modal.Body>
							<p>
								We have sent 6 digit code to your number <b>({data.phone})</b>
							</p>

							<Form.Group className='mt-3'>
								<Form.Control type='text' placeholder='Enter OTP' minLength='6' maxLength='6' required onChange={({ target }) => this.setState({ otp: target.value }) } />
							</Form.Group>
						</Modal.Body>
						<Modal.Footer>
							<Button type='submit' variant='primary'>
								Verify
							</Button>
						</Modal.Footer>
					</Form>
				</Modal>
			</>
		);
	}
}

const mapsStateToProps = state => ({
	auth: state.UserReducer.auth,
	error: state.UserReducer.error,
	payload: state.UserReducer.payload,
	otpShow: state.UserReducer.otpShow,
});

const mapDispatchToProps = dispatch => ({
	CreateAccount: payload => dispatch(CreateAccount(payload)),
});

export default connect(mapsStateToProps, mapDispatchToProps)(SignUpView);
