import { SHOW_ERROR, HIDE_ERROR, SHOW_LOADER, HIDE_LOADER } from './ConfigType';
import ConfigInitialState from './ConfigInitialState';

export default function ConfigReducer(state = ConfigInitialState, action) {
	switch (action.type) {
		case SHOW_ERROR:
			return { ...state, error: action.error };

		case HIDE_ERROR:
			return { ...state, error: null };

		case SHOW_LOADER:
			return { ...state, loading: true };

		case HIDE_LOADER:
			return { ...state, loading: false };

		default:
			return state;
	}
}
