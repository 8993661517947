import React from 'react';
import { connect } from 'react-redux';
import { Button, Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Routes from '../../../config/routes';
import Logo from '../../../assets/images/logo.jpg';
import Avatar from '../../../assets/images/avatar.jpg';
import { Modal } from 'react-bootstrap';
import { AuthRevoke, GetUser } from '../../../app/user/UserAction';
import './index.css';

class NavBar extends React.PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			profileModal: false,
		};
	}

	handleOpenSidebar = () => {
		const { handleSidebar } = this.props;
		handleSidebar(true);
	};

	handleProfileModal = profileModal => {
		this.setState({ profileModal });
	};

	handleAuthRevoke = async () => {
		const { AuthRevoke } = this.props;
		await AuthRevoke();

		return (window.location.href = Routes.account.login);
	};

	render() {
		const { profileModal } = this.state;
		const { account } = this.props;

		return (
			<div className='custom-navbar'>
				<div className='logo'>
					<Link to={Routes.client.home}>
						<Image
							src={Logo}
							className='img-fluid mt-1'
							alt='E-merchant Card'
						/>
					</Link>

					<Button
						variant='default'
						className='navbar-open-collapse'
						onClick={this.handleOpenSidebar}
					>
						<i className='fas fa-bars'></i>
					</Button>
				</div>

				<div className='overview d-flex justify-content-between align-items-center pr-5 pl-4'>
					<p className='font-medium color-grey text-small ml-3'>
						Overview / Dashboard
					</p>
					{/* <div class='custom-control custom-switch'>
							<label class='custom-control-label' for='customSwitches'>
								Live mode
							</label>
							
							<input
								type='checkbox'
								class='custom-con12trol-input'
								id='customSwitches'
							/>
						</div> */}

					<div class='dropdown'>
						<a
							class='float-right font-regular nav-link dropdown-toggle'
							href='#'
							id='navbarDropdownMenuLink'
							role='button'
							data-toggle='dropdown'
						>
							<Image
								src={Avatar}
								className='navbar-avatar img-fluid'
								alt='Full Name'
							/>
							<small className='navbar-user'>{account?.business}</small>
						</a>
						<div
							class='dropdown-menu border-0 shadow'
							aria-labelledby='navbarDropdownMenuLink'
						>
							<Link
								class='dropdown-item'
								to={Routes.account.profile}
								onClick={() => this.handleProfileModal(true)}
							>
								<small className='font-bold space-0 text-muted'> Profile</small>
							</Link>
							<a class='dropdown-item' href='#' onClick={this.handleAuthRevoke}>
								<small className='font-bold space-0 text-muted'>
									{' '}
									Sign out
								</small>
							</a>
						</div>

						<Modal
							show={profileModal}
							onHide={() => this.handleProfileModal(false)}
						>
							<Modal.Header closeButton>
								<Modal.Title>Modal heading</Modal.Title>
							</Modal.Header>
							<Modal.Body>
								Woohoo, you're reading this text in a modal!
							</Modal.Body>
							<Modal.Footer>
								<Button
									variant='secondary'
									onClick={() => this.handleProfileModal(false)}
								>
									Close
								</Button>
								<Button
									variant='primary'
									onClick={() => this.handleProfileModal(false)}
								>
									Save Changes
								</Button>
							</Modal.Footer>
						</Modal>
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => ({
	account: state.UserReducer.account,
});

const mapDispatchToProps = dispatch => ({
	AuthRevoke: () => dispatch(AuthRevoke()),
});

export default connect(mapStateToProps, mapDispatchToProps)(NavBar);
