import React from 'react';
import { connect } from 'react-redux';
import UserLayout from '../../../layouts/UserLayout';
import { ListLink, DeleteLink, CreateLink } from '../../../../app/link/LinkAction';
import Arrow from '../../../../assets/images/create-arrow.svg';
import Share from '../../../../assets/images/share.svg';
import Edit from '../../../../assets/images/edit.svg';
import Trash from '../../../../assets/images/trash.svg';
import moment from 'moment';
import Util from '../../../../core/helpers/Util';

class LinksView extends React.PureComponent {

	state = {
		data: {
			title: '',
			currency: 'RWF',
			amount: '',
			description: ''
		}
	}

	handleChange = ({ target }) => {
		const { data } = this.state;
		this.setState({ data: {...data, [target.name]: target.value} });
	}

	handleSubmit = async (e) => {
		e.preventDefault();

		const response = await this.props.CreateLink(this.state.data);
		
		window.location.reload();
	}

	async componentDidMount() {
		const { ListLink } = this.props;
		const response = await ListLink();
		console.log(response);
	}

	handleDeleteLink = async id => {
		const { DeleteLink } = this.props;
		await DeleteLink(id);

		alert('Link Deleted');
		this.componentDidMount();
	}

	render() {
		const currency = [
			"USD",
			"EUR",
			"RWF"
		];

		const { ITEMS: links } = this.props;
		const { data } = this.state;

		return (
			<UserLayout>
				<h6 className='text-primary small-text my-0'>
					<span className='font-bold'>Create a link</span>
					<span className='space-0'>
						{' '}
						of your products and get paid via it or with the e-merchant card.
					</span>
				</h6>
				<p className='my-0'>
					<small className='text-muted space-0'>
						with the generated link your are able to be paid easily within every
						african country and others abroad Africa.
					</small>
				</p>
				<div className='card border-0 shadow-sm my-4'>
					<div className='card-body py-2'>
						<form onSubmit={this.handleSubmit}>
							<div className='row'>
								<div className='col-md-3'>
									<div className='form-group'>
										<label htmlFor='name'>
											<b className='text-dark'> Title</b>
										</label>
										<input
											type='text'
											className='form-control rounded bg-white'
											name="title"
											value={data.title}
											onChange={this.handleChange}
										/>
									</div>
								</div>
								<div className='col-md-2'>
									<div className='form-group'>
										<label htmlFor='name'>
											<b className='text-dark'> Currency</b>
										</label>
										<select
											className='custom-select mr-sm-2'
											id='inlineFormCustomSelect'
											defaultValue={data.currency}
											name="currency"
											value={data.currency}
											onChange={this.handleChange}
										>
											{currency.map((c, i) => (
												<option key={i} value={c}>{c}</option>
											))}
											{/* <option value='1'>One</option>
											<option value='2'>Two</option>
											<option value='3'>Three</option> */}
										</select>
									</div>
								</div>
								<div className='col-md-3'>
									<div className='form-group'>
										<label htmlFor='name'>
											<b className='text-dark'> Price</b>
										</label>
										<input
											type='number'
											className='form-control rounded bg-white'
											placeholder='0.00'
											name="amount"
											value={data.amount}
											onChange={this.handleChange}
										/>
									</div>
								</div>
								<div className='col-md-4'>
									<div className='form-group'>
										<label htmlFor='name'>
											<b className='text-dark'>Description</b>
										</label>
										<textarea
											name='description'
											className='w-100 border-light rounded form-control'
											cols='30'
											rows='2'
											name="description"
											value={data.description}
											onChange={this.handleChange}
										></textarea>
									</div>
								</div>
							</div>
							<div className='float-right mb-2'>
								<button className="btn btn-sm bg-dark text-white rounded-pill py-0 px-4">
									<small className="font-weight-bold">Create <img src={Arrow} className='img-fluid ml-2' alt='' /></small>
								</button>
								
							</div>
						</form>
					</div>
				</div>
				
				<h6 className='font-bold space-0'>Generated Links</h6>
				
				<div className='col-lg-12'>
					<div className='row'>
					{links && links?.map(value => {
						return (
							<div className='col-xl-3 col-lg-4 col-sm-6 my-2 px-1'>
								<div className='card border-0 shadow-sm rounded-0'>
									<div className='card-body px-2 py-3'>
										<div className='d-flex justify-content-between align-items-center'>
											<h6 className='font-bold space-0 text-primary my-0 py-0'>
												{value.title}
											</h6>
											<p className='my-0 py-0'>
												<small className='font-weight-bold text-muted'>
													{moment(value.created_at).format('MMMM Do')}
												</small>
											</p>
										</div>
										<p className='font-bold space-0 my-3 text-dark'>
											{`${value.currency} ${value.amount}`}
										</p>
										<small className='space-0 text-muted'>
											{value.description}
										</small>
									</div>
									<div className='card-footer border-0 px-2'>
										<div className='d-flex justify-content-between aligh-items-center'>
											<a href='#' onClick={() => Util.copyText(`${process.env.REACT_APP_PAYMENT}/${value.id}`)}>
												<img src={Share} className='img-fluid mx-1' alt='' />
												<small
													className='font-weight-bold space-0 ml-1'
													style={{ color: '#7C8DB5' }}
												>
													Copy
												</small>
											</a>
											<div className='d-flex align-items-center'>
												<a href='#' onClick={() => this.handleDeleteLink(value.id)}>
													<img src={Trash} className='img-fluid mx-2' alt='' />
												</a>
												<a href='#'>
													<img src={Edit} className='img-fluid mx-2' alt='' />
												</a>
											</div>
										</div>
									</div>
								</div>
							</div>
						);
					})}
				</div>
				</div>
			</UserLayout>
		);
	}
}

const mapsStateToProps = state => ({
	ITEMS: state.LinkReducer.ITEMS,
});

const mapDispatchToProps = dispatch => ({
	ListLink: () => dispatch(ListLink()),
	DeleteLink: id => dispatch(DeleteLink(id)),
	CreateLink: payload => dispatch(CreateLink(payload))
});

export default connect(mapsStateToProps, mapDispatchToProps)(LinksView);
