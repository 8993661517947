import Http from '../../core/client/Http';
import {
	LIST_TRANSACTION,
	WALLET_TRANSACTION,
	LATEST_TRANSACTION,
} from './TransactionType';

export function ListTransaction() {
	return async dispatch => {
		const { data } = await Http.get('/transaction');

		return dispatch({
			type: LIST_TRANSACTION,
			payload: data,
		});
	};
}

export function WalletTramsaction() {
	return async dispatch => {
		const { data } = await Http.get('/transaction/wallet');

		return dispatch({
			type: WALLET_TRANSACTION,
			payload: data,
		});
	};
}

export function LatestTramsaction() {
	return async dispatch => {
		const { data } = await Http.get('/transaction?limit=10');

		return dispatch({
			type: LATEST_TRANSACTION,
			payload: data,
		});
	};
}
