import React from 'react';
import { connect } from 'react-redux';
import { Route } from 'react-router-dom';
import Util from '../../core/helpers/Util';
import { ShowError } from '../../app/config/ConfigAction';
import RouteMiddleware from '../middleware/RouteMiddleware';

class RouteHandler extends React.PureComponent {
	render() {
		const {
			component: Component,
			middleware,
			ShowError,
			auth,
			...rest
		} = this.props;

		return (
			<Route
				{...rest}
				render={props => {
					let access = true;

					if (middleware !== undefined) {
						try {
							for (let i = 0; i < middleware.length; i++) {
								access = new RouteMiddleware(middleware[i], auth).execute();
								if (Util.isArray(access) || !access) break;
							}
						} catch (e) {
							ShowError(500);
						}
					}

					if (Util.isArray(access)) return props.history.push(access[0]);

					if (access) return <Component {...props} />;
					return ShowError(403);
				}}
			/>
		);
	}
}

const mapsStateToProps = state => ({
	auth: state.UserReducer.auth,
});

const mapDispatchToProps = dispatch => ({
	ShowError: error => dispatch(ShowError(error)),
});

export default connect(mapsStateToProps, mapDispatchToProps)(RouteHandler);
