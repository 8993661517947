import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import AuthLayout from '../../../layouts/AuthLayout';
import Logo from '../../../../assets/images/logo.jpg';
import Routes from '../../../../config/routes';
import PhoneInput from 'react-phone-input-2';
import { AuthToken } from '../../../../app/user/UserAction';
import Navbar from '../../component/navbar';
import Util from '../../../../core/helpers/Util';

class LoginView extends React.PureComponent {
  constructor(props) {
    super(props);
    
    this.state = {
      show: false,
			error: false,
			loading: false,
      data: {
        phone: '',
        password: ''
      },
    }
  }

  componentWillReceiveProps({ error }) {
		this.setState({ error });
  };

  handleSubmit = async (e) => {
		e.preventDefault();
		
		this.setState({ loading: true });

    const { data } = this.state;
    const { AuthToken } = this.props;

		await AuthToken(data);
		
		this.setState({ loading: false });
  }

  handleChange = ({ target }) => {
    const { data } = this.state;
    this.setState({ data: {...data, [target.name]: target.value} });
  }

  render() {
    const { data, show, error, loading } = this.state;

    return (
      <>
				<Navbar theme={true} />
				<div className='bg-white shadow' />
				<div style={{ backgroundColor: '#EAECEF' }}>
					<div className='container py-5'>
						<div className='row justify-content-center my-4'>
							<div className='col-md-5 px-md-5 loginForm'>
								<div className='card border-0 shadow-sm'>
									<div className='card-body'>
										<h3 className='font-weight-bold text-center'>
											Login to continue
										</h3>
										<form onSubmit={this.handleSubmit} className='mt-5'>
											<div className='form-group my-3'>
												<label htmlFor='email'>
													<b className='loginForm'> Mobile Phone</b>
												</label>
                        <PhoneInput
                          regions={'africa'}
                          country={'rw'}
                          value={data.phone}
                          onChange={(phone) => this.setState({ data: { ...data, phone }})}
													inputClass="w-100 f-13 py-1"
													disabled={loading}
                      />
											</div>
											<label htmlFor=''>
												<b> 4-Digit Pin</b>
											</label>
											<div className='input-group mb-1'>
												<input
													type={show ? 'text' : 'password'}
													className='form-control border-right-0'
													placeholder='****'
													value={data.password}
													name='password'
													onChange={this.handleChange}
													readOnly={loading}
													required
													minLength='4'
													maxLength='4'
												/>
												<div className='input-group-append'>
													<span
														onClick={() => this.setState({ show: !show })}
														className='input-group-text border-left-0'
														id='basic-addon2'
													>
														{
															show ? <i className='fas fa-eye-slash'></i> : <i className='fas fa-eye'></i>
														}
													</span>
												</div>
											</div>
											<div className='media m-2'>
												{error && <div>Error</div>}
												<div className='media-body ml-2'>
													{error && (
														<span className='text-danger'>
															Incorrect Phone number or PIN.{' '}
														</span>
													)}
												</div>
											</div>
											<div className='pt-1'>
												<button className='btn btn-primary btn-block py-2 my-3' disabled={loading}>
													<b>Login</b>
												</button>
											</div>
											<a href='#' style={{ color: '#128387' }}>
												<h6>Forgotten Pin?</h6>{' '}
											</a>
											<div className='mt-4'>
												<h5 className='text-center'>
													Want to receive payments ?
												</h5>
												<div className='d-flex justify-content-between mt-4 flex-wrap align-items-center'>
													{/* <p className="font-weight-light">Signing up takes less than <br/> a minute</p> */}
													<Link
														to={Util.route(Routes.account.signup)}
														className='btn btn-outline-primary py-2 btn-block'
													>
														<b>Create an account </b>
													</Link>
												</div>
											</div>
										</form>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</>
    )
  }
}

const mapsStateToProps = state => ({
  error: state.UserReducer.errorAuth
});

const mapDispatchToProps = dispatch => ({
  AuthToken: payload => dispatch(AuthToken(payload)),
});

export default connect(mapsStateToProps, mapDispatchToProps)(LoginView);
