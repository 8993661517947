import React from "react";
import { Button, Image } from "react-bootstrap";
import Logo from '../../../assets/images/logo.jpg';
import Avatar from '../../../assets/images/avatar.jpg';
import {Modal} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import './index.css';
import Routes from "../../../config/routes";

export default class DocsNav extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      profileModal: false
    }
  }

  handleOpenSidebar = () => {
    const { handleSidebar } = this.props;
    handleSidebar(true);
  }

  handleProfileModal = profileModal => {
    this.setState({ profileModal });
  }

  handleLogOut = () => {
    localStorage.removeItem('token');
  }

  render() {
    const { profileModal } = this.state;

    return (
      <div className='custom-navbar'>
        <div className='logo'>
          <Link to={Routes.client.home}>
            <Image src={Logo} className='img-fluid mt-1' alt='E-merchant Card' />
          </Link>

          <Button variant='default' className='navbar-open-collapse' onClick={this.handleOpenSidebar}>
            <i className='fas fa-bars'></i>
          </Button>
        </div>

        <div className="overview d-flex justify-content-between align-items-center">
          <p className='font-weight-bold color-grey'>
            Overview / Dashboard
          </p>
          <div class="dropdown">
            <a class='float-right font-regular nav-link dropdown-toggle'  href="#" id="navbarDropdownMenuLink" role="button" data-toggle="dropdown">
              <Image src={Avatar} className='navbar-avatar img-fluid' alt='Full Name' />
              <small className='navbar-user'>
                My Account
              </small>
            </a>
            <div class="dropdown-menu border-0 shadow" aria-labelledby="navbarDropdownMenuLink">
              <Link class="dropdown-item" to={Routes.account.login} onClick={() => this.handleProfileModal(true)}><small className="font-weight-bold text-muted"> Login</small></Link>
              <Link class="dropdown-item" to={Routes.account.signup} onClick={this.handleLogOut}><small className="font-weight-bold text-muted"> Register</small></Link>
            </div>

            <Modal show={profileModal} onHide={() => this.handleProfileModal(false)}>
              <Modal.Header closeButton>
                <Modal.Title>Modal heading</Modal.Title>
              </Modal.Header>
              <Modal.Body>Woohoo, you're reading this text in a modal!</Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={() => this.handleProfileModal(false)}>
                  Close
                </Button>
                <Button variant="primary" onClick={() => this.handleProfileModal(false)}>
                  Save Changes
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
        </div>
      </div>
    );
  }
}
