import Http from '../../core/client/Http';
import { notify } from 'react-notify-toast';
import { AUTH_TOKEN, VIEW_ACCOUNT, VIEW_USERS } from './UserType';

export function SwithcMode() {
	return async () => {
		await Http.get('/user/account/mode');
		alert('Account Mode Switched');
		window.location.reload();
	};
}

export function updateAccount(payload) {
	return async () => {
		await Http.put('/user/account', payload);
		alert('Account updated');
		window.location.reload();
	};
}

export function CreateAccount(payload) {
	return async dispatch => {
		const { data, status } = await Http.post('/user/account', payload);

		if (status === 201) {
			dispatch({
				type: AUTH_TOKEN,
				auth: false,
				error: false,
				payload: data,
				otpShow: true,
			});

			return;
		}

		return dispatch({
			type: AUTH_TOKEN,
			auth: false,
			error: true,
			payload: data,
			otpShow: false,
		});
	};
}

export function AuthToken(payload) {
	return async dispatch => {
		const { data, status } = await Http.post('/user/auth/token?otp=1', payload);

		if (status === 200) {
			localStorage.setItem('access_token', data.access);

			dispatch({
				type: AUTH_TOKEN,
				auth: true,
				error: false,
				payload: data,
				otpShow: false,
			});

			return window.location.reload();
		}

		return dispatch({
			type: AUTH_TOKEN,
			auth: false,
			error: true,
			payload: data,
			otpShow: false,
		});
	};
}

export function GetUser() {
	return async dispatch => {
		const { data, status } = await Http.get('/user/account');

		if (status === 200) {
			return dispatch({
				type: VIEW_ACCOUNT,
				payload: data,
			});
		}

		localStorage.removeItem('access_token');

		dispatch({
			type: AUTH_TOKEN,
			auth: false,
			error: false,
			payload: {},
		});

		return window.location.reload();
	};
}

export function ViewUsers(id) {
	return async dispatch => {
		const response = await Http.get('/user/account/search?merchant=' + id);

		return dispatch({
			type: VIEW_USERS,
			payload: response,
		});
	};
}

export function AuthRevoke() {
	return async dispatch => {
		localStorage.removeItem('access_token');

		return dispatch({
			type: AUTH_TOKEN,
			auth: false,
			error: false,
			payload: {},
		});
	};
}
