import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import DocsBar from '../components/Molecules/DocsBar';
import DocsNav from '../components/Molecules/DocsNav';
import './index.css';

export default class DocsLayout extends React.PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			mobileSidebar: false,
		};
	}

	componentDidMount() {
		window.scrollTo(0, 0);
	}

	handleSidebar = mobileSidebar => {
		this.setState({ mobileSidebar });
	};

	render() {
		const { children } = this.props;
		const { mobileSidebar } = this.state;

		return (
			<div className='user-layout'>
				<DocsNav handleSidebar={this.handleSidebar} />

				<div className='content'>
					<div className={`sidebar ${mobileSidebar && `sidebar-collapse`}`}>
						<Container fluid className='p-0'>
							<Row>
								<Col md={12}>
									<DocsBar
										mobileSidebar={mobileSidebar}
										handleSidebar={this.handleSidebar}
									/>
								</Col>
							</Row>
						</Container>
					</div>

					<div className='box-content'>
						<Container>
							<Row>
								<Col md={12}>
									<div className='box-children px-3 py-4'>{children}</div>
								</Col>
							</Row>
						</Container>
					</div>
				</div>
			</div>
		);
	}
}
