import { createStore, applyMiddleware, compose } from 'redux';
import ReduxThunk from 'redux-thunk';

import Reducer from './Reducer';

let middleware = [ReduxThunk];

// handle development middleware
if (process.env.NODE_ENV !== 'production') {
	const { LoggerMiddleware } = require('../core/middleware/ReduxMiddleware');
	middleware = [...middleware, LoggerMiddleware];
}

const initialState = {};

const composeEnhancers =
	typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
		? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
		: compose;

const enhancer = composeEnhancers(applyMiddleware(...middleware));
const Store = createStore(Reducer, initialState, enhancer);

export default Store;
