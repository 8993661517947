import React from 'react';
import { Link } from 'react-router-dom';
import AuthLayout from '../../../../layouts/AuthLayout';

export default class ForgotPasswordView extends React.PureComponent {
  render() {
    return (
      <AuthLayout>
        <div>
          <h2>Forgot Password View | <Link to='/login'>Login View</Link></h2>
        </div>
      </AuthLayout>
    )
  }
}
