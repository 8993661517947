import Axios from 'axios';
import Store from '../../app/Store';
import Util from '../helpers/Util';
import { ShowError } from '../../app/config/ConfigAction';

class http {
	constructor() {
		let service = Axios.create({
			baseURL: process.env.REACT_APP_BACKEND,
			headers: {
				Authorization: `Bearer ${localStorage.getItem('access_token')}`,
			},
		});

		service.interceptors.response.use(this.handleSuccess, this.handleError);
		this.service = service;
	}

	handleSuccess(response) {
		return response;
	}

	handleError(error) {
		return Promise.reject(error);
	}

	handleResponse(response) {
		return { data: response?.data, status: response?.status };
	}

	get(path, params = null) {
		return this.service
			.request({
				method: 'GET',
				url: path,
				responseType: 'json',
				params: params,
			})
			.then(response => {
				return this.handleResponse(response);
			})
			.catch(error => {
				console.log('http', error.response);

				return this.handleResponse(error.response);
			});
	}

	post(path, payload) {
		return this.service
			.request({
				method: 'POST',
				url: path,
				responseType: 'json',
				data: payload,
			})
			.then(response => {
				return this.handleResponse(response);
			})
			.catch(error => {
				return this.handleResponse(error.response);
			});
	}

	put(path, payload) {
		return this.service
			.request({
				method: 'PUT',
				url: path,
				responseType: 'json',
				data: payload,
			})
			.then(response => {
				return this.handleResponse(response);
			})
			.catch(error => {
				return this.handleResponse(error.response);
			});
	}

	delete(path) {
		return this.service
			.delete(path)
			.then(response => {
				return this.handleResponse(response);
			})
			.catch(error => {
				return this.handleResponse(error.response);
			});
	}
}

export default new http();
