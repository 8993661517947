import { SHOW_ERROR, HIDE_ERROR } from './ConfigType';

export function ShowError(error) {
	return dispatch => {
		dispatch({
			type: SHOW_ERROR,
			error: error,
		});
	};
}

export function HideError() {
	return dispatch => {
		dispatch({
			type: HIDE_ERROR,
		});
	};
}
