import React from 'react';
import { connect } from 'react-redux';
import ErrorView from '../../resources/views/error/ErrorView';

class ErrorHandler extends React.PureComponent {
	render() {
		const { children, error } = this.props;

		if (error !== null) {
			return <ErrorView />;
		}

		return children;
	}
}

const mapsStateToProps = state => ({
	error: state.ConfigReducer.error,
});

export default connect(mapsStateToProps)(ErrorHandler);
