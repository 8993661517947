import { AUTH_TOKEN, VIEW_ACCOUNT, VIEW_USERS } from './UserType';
import UserInitialState from './UserInitialState';

export default function UserReducer(state = UserInitialState, action) {
	switch (action.type) {
		case AUTH_TOKEN:
			return {
				...state,
				auth: action.auth,
				payload: action.payload,
				errorAuth: action.error,
				otpShow: action.otpShow,
			};

		case VIEW_ACCOUNT:
			const { payload: account } = action;
			return { ...state, account };

		case VIEW_USERS:
			return { ...state, users: action.payload };

		default:
			return state;
	}
}
