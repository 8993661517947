import React from 'react';
import DocsLayout from '../../layouts/DocsLayout';

export default class ShopifyView extends React.PureComponent {
  render() {
    return (
      <DocsLayout>
        <h5 className="font-weight-bold">Plugin for Shopify</h5>
        <div className="card border-0 shadow rounded-0 my-4">
          <div className="card-body">
            <p style={{letterSpacing: '0.1px'}}>Integrating E-merchant Card with your Shopify shop is super easy with our inbuilt plugin for Shopify. You just have to install E-merchant Card  with a single click of a button & configure it with your E-merchant account details.</p>
            <br/>
          </div>
        </div>
        <div className="card border-0 shadow mb-4">
          <div className="card-body">
            <p style={{letterSpacing: '0.1px'}}>Please follow the below steps.</p>
            <ol className="ml-5 mt-3">
              <p><li className="my-2">Go to <a href="#" className="text-active">EMC Payment Gateway installation page</a> on Shopify (Click the link)</li></p>
              <p><li className="my-2">Sign in to your Shopify admin if you haven't signed in yet</li></p>
              <p><li className="my-2">Click 'Install Payment Provider' button</li></p>
              <p><li className="my-2">Under 'Accept credit cards', click the drop down & select 'EMC Payment Gateway' if it's not already selected</li></p>
              <p><li className="my-2">Enter your Merchant ID (You can find it on the Settings &gt; Domains & Credentials section of your <a href="#" className="text-active"> E-merchant account</a>)</li></p>
              <p><li className="my-2">Enter your Merchant Secret (You can generate a Merchant secret for your domain at Settings &gt; Domains & Credentials section of your <a href="#" className="text-active">E-merchant account</a>)</li></p>
              <p><li className="my-2">Untick 'Use test mode' (Tick it if you're testing your integration with a E-merchant Sandbox Account)</li></p>
              <p><li className="my-2">Finally click 'Activate'.</li></p>
            </ol>
            <p>These steps are quite straightforward & simple to follow. But to clarify you in detail, here are the specific detailed steps you need to follow to fulfill the above steps.</p>
          </div>
        </div>
      </DocsLayout>
    )
  }
}