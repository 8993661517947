import React from 'react';
import DocsLayout from '../../layouts/DocsLayout';

export default class WordpressView extends React.PureComponent {
  render() {
    return (
      <DocsLayout>
        <h5 className="font-weight-bold">Plugin for Wordpress</h5>
        <div className="card border-0 shadow rounded-0 my-4">
          <div className="card-body">
            <p style={{letterSpacing: '0.1px'}}>Integrating  E-merchant Card with your Wordpress WooCommerce website is super easy with our EMC Plugin for WooCommerce. You just have to download it from Wordpress Plugin Store, upload it to your server & configure it with your E-merchant Account details to start accepting online payments.</p>
            <br/>
            <p style={{letterSpacing: '0.1px'}}>Please refer to the detailed steps below..</p>
          </div>
        </div>
        <div className="card border-0 shadow my-1">
          <div className="card-body py-3">
            <p className="font-weight-bold">WooCommerce Settings</p>
          </div>
        </div>
        <div className="card border-0 shadow mb-4">
          <div className="card-body">
            <ol className="ml-5">
              <p><li className="my-2">Download the EMC Plugin for WooCommerce from <a href="#" className="text-active"> Wordpress Plugin Directory</a></li></p>
              <p><li className="my-2">Upload the 'woocommerce-emc' folder to your WordPress 'plugins' directory under '/wp-content/plugins'</li></p>
              <p><li className="my-2">In your WordPress admin, go to 'Plugins' and Activate the 'WooCommerce EMC Payment Gateway' plugin</li></p>
              <p><li className="my-2">Go to your WooCommerce &gt; Settings page, click on 'Checkout' tab & click the 'E-merchant' link</li></p>
              <p><li className="my-2">Tick the checkbox to Enable EMC</li></p>
              <p><li className="my-2">Enter the payment title and description or keep the default</li></p>
              <p><li className="my-2">Enter your Merchant ID (You can find it on the Settings &gt; E-merchant Plugins section of your <a href="#" className="text-active"> E-merchant account</a>)</li></p>
              <p><li className="my-2">Enter your Merchant Secret (You can find it on the Settings &gt; E-merchant Plugins section of your <a href="#" className="text-active"> E-merchant account</a>)</li></p>
              <p><li className="my-2">Do not tick the checkbox to Enable Sandbox Mode (Tick it only if you're testing your integration with a <a href="#" className="text-active"> E-merchant Sandbox Account</a>)</li></p>
              <p><li className="my-2">Click Save Changes</li></p>
            </ol>
            <p>That's it. Now you are ready to accept payments from your WooCommerce website.</p>
          </div>
        </div>
      </DocsLayout>
    )
  }
}