import {
	LIST_LINK,
	CIPHER_LINK,
	CREATE_TRANSFER,
	CREATE_LINK,
	DELETE_LINK,
} from './LinkType';
import LinkInitialState from './LinkInitialState';

export default function LinkReducer(state = LinkInitialState, action) {
	switch (action.type) {
		case LIST_LINK:
			return {
				...state,
				ITEMS: action.payload,
				error: action.error,
			};

		case CIPHER_LINK:
			return {
				...state,
				link: action.payload,
			};

		case CREATE_LINK:
			return { ...state, data: action.payload };

		case CREATE_TRANSFER:
			return { ...state, data: action.payload };

		case DELETE_LINK:
			return { ...state, data: action.payload };

		default:
			return state;
	}
}
