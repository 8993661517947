import React from 'react';
import Routes from '../config/routes';
import RouteHandler from '../core/helpers/RouteHandler';
import DashboardView from '../resources/views/account/home/DashboardView';
import LoginView from '../resources/views/account/auth/LoginView';
import ForgotPasswordView from '../resources/views/account/auth/password/ForgotPassword';
import TransactionView from '../resources/views/account/transaction/TransactionView';
import LinksView from '../resources/views/account/links/LinksView';

export default class AccountRoutes extends React.PureComponent {
	render() {
		return (
			<>
				<RouteHandler
					exact
					path={Routes.account.dashboard}
					component={DashboardView}
					middleware={['access:auth']}
				/>
				<RouteHandler
					exact
					path={Routes.account.transaction}
					component={TransactionView}
					middleware={['access:auth']}
				/>
				<RouteHandler
					exact
					path={Routes.account.links}
					component={LinksView}
					middleware={['access:auth']}
				/>
				<RouteHandler
					exact
					path={Routes.account.login}
					component={LoginView}
					middleware={['access:guest']}
				/>
				<RouteHandler
					exact
					path={Routes.account.password.forgot}
					component={ForgotPasswordView}
					middleware={['access:guest']}
				/>
			</>
		);
	}
}
