import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Col, Container, Row } from 'react-bootstrap';
import NavBar from '../components/Molecules/NavBar';
import SideBar from '../components/Molecules/Sidebar';
import { GetUser } from '../../app/user/UserAction';
import Notifications from 'react-notify-toast';
import './index.css';

const UserLayout = ({ children }) => {
	const [mobileSidebar, setMobileSidebar] = useState(false);
	const [loading, setLoading] = useState(true);

	const handleSidebar = mobileSidebar => {
		setMobileSidebar(mobileSidebar);
	};

	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(GetUser());
		setLoading(false);
	}, [dispatch]);

	if (loading) {
		return null;
	} else {
		return (
			<div className='user-layout'>
				<Notifications />

				<NavBar handleSidebar={handleSidebar} />

				<div className='content'>
					<div className={`sidebar ${mobileSidebar && `sidebar-collapse`}`}>
						<Container fluid className='p-0'>
							<Row>
								<Col md={12}>
									<SideBar
										mobileSidebar={mobileSidebar}
										handleSidebar={handleSidebar}
									/>
								</Col>
							</Row>
						</Container>
					</div>

					<div className='box-content'>
						<Container fluid>
							<Row>
								<Col md={12}>
									<div className='box-children px-5 py-4'>{children}</div>
								</Col>
							</Row>
						</Container>
					</div>
				</div>
			</div>
		);
	}
};

export default UserLayout;
