const UserInitialState = {
	auth: false,
	error: false,
	errorAuth: false,
	payload: {},
	account: {},
	users: {},
	otpShow: false,
};

export default UserInitialState;
