import { combineReducers } from 'redux';
import ConfigReducer from './config/ConfigReducer';
import UserReducer from './user/UserReducer';
import TransactionReducer from './transaction/TransactionReducer';
import LinkReducer from './link/LinkReducer';

export default combineReducers({
	ConfigReducer,
	UserReducer,
	TransactionReducer,
	LinkReducer,
});
