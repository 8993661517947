import React from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MobileMoney from '../../../assets/images/mobile-money.png';
import Visa from '../../../assets/images/visa.png';
import MasterCard from '../../../assets/images/mastercard.png';
import Navbar from '../component/navbar';
import Ghana from '../../../assets/images/flag-gh.svg';
import Rwanda from '../../../assets/images/flag-rw.svg';
import Kenya from '../../../assets/images/flag-ke.svg';
import Uganda from '../../../assets/images/flag-ug.svg';
import Nigeria from '../../../assets/images/flag-ng.svg';
import Zambia from '../../../assets/images/flag-zm.svg';
import Next from '../../../assets/images/next.svg';

const HelpView = () => {
  
  return (  
    <>
      <Navbar />
      <div className="bg-image flex-column justify-content-center">
        <div className="container text-center d-none d-md-flex flex-column">
          <h3 className="font-weight-bold mt-5 mb-4 text-white">A wide choice of ways to Pay & Get paid</h3>
          <div className="row my-lg-5 justify-content-center">
            <div className="col-lg-3 col-md-4 col-6">
              <div className="card border-0 shadow">
                <div className="card-body text-center py-5">
                  <img src={Visa} alt=""/>
                  <p className="text-muted mt-3">Collect payments from your customers worldwide through Visa Cards.</p>
                  <a href="#" className="text-info"><small><i className="fa fa-question-circle-o"></i> Learn more</small></a>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-4">
              <div className="card border-0 shadow">
                <div className="card-body text-center py-5">
                  <img src={MasterCard} alt=""/>
                  <p className="text-muted mt-3">Send & Collect payments worldwide through Master Cards.</p>
                  <a href="#" className="text-info"><small><i className="fa fa-question-circle-o"></i> Learn more</small></a>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-4">
              <div className="card border-0 shadow">
                <div className="card-body text-center py-5">
                  <img src={MobileMoney} alt=""/>
                  <p className="text-muted mt-3">With Mobile Money, Pay & Get paid across 18 countries.</p>
                  <a href="#" className="text-info"><small><i className="fa fa-question-circle-o"></i> Learn more</small></a>
                </div>
              </div>
            </div>  
          </div>
        </div>
        <h5 className="font-weight-bold d-block d-md-none m-3 text-center text-white">A wide choice of ways to Pay & Get paid</h5>
      </div>
      <div className="container text-center d-flex d-md-none flex-column">
        <div className="row my-lg-5 justify-content-center">
          <div className="col-lg-3 col-md-4 my-2">
            <div className="card border-0 shadow">
              <div className="card-body text-center py-5">
                <img src={Visa} alt=""/>
                <p className="text-muted mt-3">Collect payments from your customers worldwide through Visa Cards.</p>
                <a href="#" className="text-info"><small><i className="fa fa-question-circle-o"></i> Learn more</small></a>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-4 my-2">
            <div className="card border-0 shadow">
              <div className="card-body text-center py-5">
                <img src={MasterCard} alt=""/>
                <p className="text-muted mt-3">Send & Collect payments worldwide through Master Cards.</p>
                <a href="#" className="text-info"><small><i className="fa fa-question-circle-o"></i> Learn more</small></a>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-4 my-2">
            <div className="card border-0 shadow">
              <div className="card-body text-center py-5">
                <img src={MobileMoney} alt=""/>
                <p className="text-muted mt-3">With Mobile Money, Pay & Get paid across 18 countries.</p>
                <a href="#" className="text-info"><small><i className="fa fa-question-circle-o"></i> Learn more</small></a>
              </div>
            </div>
          </div>  
        </div>
      </div>
      <section className="bg-light">
        {/* <div className="container py-5 text-center">
          <h6 className="font-weight-light">Pay By:</h6>
          <div className="d-flex flex-wrap justify-content-center">
            <img src={Visa} className="mx-1" alt=""/>
            <img src={Maestro} className="mx-1" alt=""/>
            <img src={MasterCard} className="mx-1" alt=""/>
            <img src={Apple} className="mx-1" alt=""/>
          </div>
        </div> */}
        <div>
          <div className="container text-center py-4">
            <h3>Pay & Get paid across 54 African countries using credit cards and over 18 countries using Mobile money.</h3>
            <p className="text-muted">Popular countries</p>
            <div className="row m-md-5 px-md-5">
              <div className="col-lg-4 col-sm-6 my-3">
                <div className="card border-0 shadow" style={{backgroundColor: "#00b9ff"}}>
                  <div className="card-body py-3">
                    <div className="d-flex flex-wrap justify-content-between align-items-center">
                      <div className="media align-items-center">
                        <img src={Ghana} alt=""/>
                        <div className="media-body ml-3">
                          <span className="text-white font-weight-bold"> Ghana</span>
                        </div>
                      </div>
                      <img src={Next} alt=""/>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-sm-6 my-3">
                <div className="card border-0 shadow" style={{backgroundColor: "#00b9ff"}}>
                  <div className="card-body py-3">
                    <div className="d-flex flex-wrap justify-content-between align-items-center">
                      <div className="media align-items-center">
                        <img src={Rwanda} alt=""/>
                        <div className="media-body ml-3">
                          <span className="text-white font-weight-bold"> Rwanda</span>
                        </div>
                      </div>
                      <img src={Next} alt=""/>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-sm-6 my-3">
                <div className="card border-0 shadow" style={{backgroundColor: "#00b9ff"}}>
                  <div className="card-body py-3">
                    <div className="d-flex flex-wrap justify-content-between align-items-center">
                      <div className="media align-items-center">
                        <img src={Kenya} alt=""/>
                        <div className="media-body ml-3">
                          <span className="text-white font-weight-bold"> Kenya</span>
                        </div>
                      </div>
                      <img src={Next} alt=""/>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-sm-6 my-3">
                <div className="card border-0 shadow" style={{backgroundColor: "#00b9ff"}}>
                  <div className="card-body py-3">
                    <div className="d-flex flex-wrap justify-content-between align-items-center">
                      <div className="media align-items-center">
                        <img src={Uganda} alt=""/>
                        <div className="media-body ml-3">
                          <span className="text-white font-weight-bold"> Uganda</span>
                        </div>
                      </div>
                      <img src={Next} alt=""/>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-sm-6 my-3">
                <div className="card border-0 shadow" style={{backgroundColor: "#00b9ff"}}>
                  <div className="card-body py-3">
                    <div className="d-flex flex-wrap justify-content-between align-items-center">
                      <div className="media align-items-center">
                        <img src={Nigeria} alt=""/>
                        <div className="media-body ml-3">
                          <span className="text-white font-weight-bold"> Nigeria</span>
                        </div>
                      </div>
                      <img src={Next} alt=""/>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-sm-6 my-3">
                <div className="card border-0 shadow" style={{backgroundColor: "#00b9ff"}}>
                  <div className="card-body py-3">
                    <div className="d-flex flex-wrap justify-content-between align-items-center">
                      <div className="media align-items-center">
                        <img src={Zambia} alt=""/>
                        <div className="media-body ml-3">
                          <span className="text-white font-weight-bold"> Zambia</span>
                        </div>
                      </div>
                      <img src={Next} alt=""/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <button className="btn btn-outline-dark px-4"><b> View all countries</b></button>
            <div className="mt-5 text-center ">
              <h3 className="font-weight-bold">Frequently asked questions</h3>
              <div className="my-5">
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    className="py-2"

                  >
                    <Typography className="font-weight-bold">How to pay using E-merchant Card ?</Typography>
                  </AccordionSummary>
                  <AccordionDetails className="bg-light border-lefts">
                    <Typography className="text-left">
                      You have to enter the amount money you want to pay and the Merchant ID of the merchant.  Once you confirm that the ID and name of merchant match, choose the "payment method" and click on "Continue" then you will be redirect on the payment where you will fill the payment information's and validate the payment. 
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    className="py-2"
                  >
                    <Typography className="font-weight-bold">How to receive payments ?</Typography>
                  </AccordionSummary>
                  <AccordionDetails className="bg-light border-lefts">
                    <Typography className="text-left">
                      First, you neet to create a Merchant Account (emc.xyz/register) . Once the application is successful you will get access to the dashboard where you will be able to generate payment links and share to your customers worldwide. 
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    className="py-2"
                  >
                    <Typography className="font-weight-bold">How long does it take ?</Typography>
                  </AccordionSummary>
                  <AccordionDetails className="bg-light border-lefts">
                    <Typography className="text-left">
                      In most cases, transfers arrive instantly, however some transfers can take longer depending on how the money is being received. Your transfer will not start until we have received authorisation from your payment provider.
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </div>
              <button className="btn btn-outline-dark px-5 py-2"><b> Visit our FAQs</b></button>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
 
export default HelpView;