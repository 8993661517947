const allowedCountries = [
	{
		name: 'Benin',
		abbr: 'bj',
		currency: 'BWP',
		image: 'https://cdn.emc.xyz/images/flag/circle/benin.png',
	},

	{
		name: 'Burkina Faso',
		abbr: 'bf',
		currency: 'XOF',
		image: 'https://cdn.emc.xyz/images/flag/circle/burkina-faso.png',
	},
	{
		name: 'Cameroon',
		abbr: 'cm',
		currency: 'XAF',
		image: 'https://cdn.emc.xyz/images/flag/circle/cameroon.png',
	},

	{
		name: 'Central Afrian Republic',
		abbr: 'cf',
		currency: 'XAF',
		image:
			'https://cdn.emc.xyz/images/flag/circle/central-african-republic.png',
	},

	{
		name: 'Chad',
		abbr: 'td',
		currency: 'XAF',
		image: 'https://cdn.emc.xyz/images/flag/circle/chad.png',
	},
	{
		name: 'DR Congo',
		abbr: 'cd',
		image:
			'https://cdn.emc.xyz/images/flag/circle/democratic-republic-of-congo.png',
	},
	{
		name: "Cote d'ivoire",
		abbr: 'ci',
		currency: 'XOF',
		image: 'https://cdn.emc.xyz/images/flag/circle/ivory-coast.png',
	},
	{
		name: 'Equatorial Guinea',
		abbr: 'gq',
		image: 'https://cdn.emc.xyz/images/flag/circle/equatorial-guinea.png',
	},
	{
		name: 'Gabon',
		abbr: 'ga',
		currency: 'XAF',
		image: 'https://cdn.emc.xyz/images/flag/circle/gabon.png',
	},
	{
		name: 'Ghana',
		abbr: 'gh',
		currency: 'GHS',
		image: 'https://cdn.emc.xyz/images/flag/circle/ghana.png',
	},

	{
		name: 'Guinea-Bissau',
		abbr: 'gw',
		image: 'https://cdn.emc.xyz/images/flag/circle/guinea-bissau.png',
	},

	{
		name: 'Kenya',
		abbr: 'ke',
		currency: 'KES',
		image: 'https://cdn.emc.xyz/images/flag/circle/kenya.png',
	},
	{
		name: 'Mali',
		abbr: 'ml',
		currency: 'XOF',
		image: 'https://cdn.emc.xyz/images/flag/circle/mali.png',
	},
	{
		name: 'Niger',
		abbr: 'ne',
		image: 'https://cdn.emc.xyz/images/flag/circle/niger.png',
	},
	{
		name: 'Rwanda',
		abbr: 'rw',
		currency: 'RWF',
		image: 'https://cdn.emc.xyz/images/flag/circle/rwanda.png',
	},
	{
		name: 'Senegal',
		abbr: 'sn',
		currency: 'XOF',
		image: 'https://cdn.emc.xyz/images/flag/circle/senegal.png',
	},
	{
		name: 'Togo',
		abbr: 'tg',
		currency: 'XOF',
		image: 'https://cdn.emc.xyz/images/flag/circle/togo.png',
	},
	{
		name: 'Uganda',
		abbr: 'ug',
		image: 'https://cdn.emc.xyz/images/flag/circle/uganda.png',
	},
	{
		name: 'Zambia',
		abbr: 'zm',
		currency: 'ZMW',
		image: 'https://cdn.emc.xyz/images/flag/circle/zambia.png',
	},
];

export default allowedCountries;
