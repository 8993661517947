import React from 'react';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import ErrorHandler from './core/helpers/ErrorHandler';
import RouteHandler from './core/helpers/RouteHandler';
import ErrorView from './resources/views/error/ErrorView';
import ClientRoutes from './routes/Client';
import AccountRoutes from './routes/Account';
import DocumentationRoutes from './routes/Documentation';
import Routes from './config/routes';
import TransactionPaymentView from './resources/views/account/transaction/TransactionPayment';

export default class App extends React.PureComponent {
	render() {
		return (
			<Router>
				<ErrorHandler>
					<Switch>
						<ClientRoutes />
						<AccountRoutes />
						<DocumentationRoutes />

						{/* <RouteHandler
							exact
							path={Routes.client.payment}
							component={TransactionPaymentView}
							middleware={['access:public']}
						/> */}

						<RouteHandler component={ErrorView} />
					</Switch>
				</ErrorHandler>
			</Router>
		);
	}
}
