import Routes from '../../config/routes';
import Util from '../helpers/Util';

class RouteMiddleware {
	constructor(middleware, auth) {
		const _middleware = Util.string(middleware, ':');

		this._auth = auth;

		this._name = _middleware[0];
		this._value = Util.string(_middleware[1], '|');
	}

	execute() {
		switch (this._name) {
			case 'access':
				return this.handleAccess();

			default:
				return true;
		}
	}

	handleAccess() {
		const token = localStorage.getItem('access_token');
		const value = this._value[0];

		if (value === 'public') return true;

		if (!token && value === 'guest') return true;

		if (token && value === 'auth') return true;

		if (value === 'guest') return [Routes.account.dashboard];
		if (value === 'auth') return [Routes.account.login];
	}
}

export default RouteMiddleware;
