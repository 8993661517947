import TransactionIntialState from './TransactionIntialState';
import {
	LIST_TRANSACTION,
	WALLET_TRANSACTION,
	LATEST_TRANSACTION,
} from './TransactionType';

export default function TransactionReducer(
	state = TransactionIntialState,
	action
) {
	switch (action.type) {
		case LIST_TRANSACTION:
			const { payload: transaction } = action;

			return {
				...state,
				transaction,
			};

		case WALLET_TRANSACTION:
			const { payload: wallet } = action;

			return {
				...state,
				wallet,
			};

		case LATEST_TRANSACTION:
			const { payload: latest } = action;

			return {
				...state,
				latest,
			};

		default:
			return state;
	}
}
