import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Routes from '../../../config/routes';
import Util from '../../../core/helpers/Util';
import './index.css';

class ErrorView extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      error: '',
      title: '',
      message: ''
    }
  }

  componentDidMount() {
    const { error } = this.props;

    switch(error) {
      case 500:
        this.setState({ error: error, title: 'Something has gone seriously wrong', message: "It's always time for a coffee break. We should be back by the time you finish your coffee." });
      break;

      case 401:
        this.setState({ error: error, title: 'Unauthorized', message: "This server could not verify that you are authorized to access the page you're requesting" });
      break;

      case 403:
        this.setState({ error: error, title: 'Access forbidden', message: "The page you're trying to access has restricted access please refer to your system administrator." });
      break;

      default:
        this.setState({ error: 404, title: 'Page not found', message: "The page you are looking for might have been removed, had it's name changed or its temporarily unvailable." });
      break;
    }
  }

  render() {
    const { error, title, message } = this.state;

    return (
      <div>
        <h3>{`${error} - ${title}`}</h3>
        <p>{message}</p>

        <Link to={Util.route(Routes.dashboard)}>
          Back Home
        </Link>
      </div>
    );
  }
}

const mapsStateToProps = state => ({
	error: state.ConfigReducer.error
});

export default connect(mapsStateToProps, null)(ErrorView);
