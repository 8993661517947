import React from 'react';
import Routes from '../config/routes';
import RouteHandler from '../core/helpers/RouteHandler';
import HomeView from '../resources/views/client/HomeView';

import TransactionPaymentView from '../resources/views/account/transaction/TransactionPayment';

import DashboardView from '../resources/views/account/home/DashboardView';
import LoginView from '../resources/views/account/auth/LoginView';
import ForgotPasswordView from '../resources/views/account/auth/password/ForgotPassword';
import TransactionView from '../resources/views/account/transaction/TransactionView';
import LinksView from '../resources/views/account/links/LinksView';
import SettingsAccountView from '../resources/views/account/settings/SettingsView';
import ProfileAccountView from '../resources/views/account/settings/ProfileView';

import PaymentView from '../resources/views/docs/PaymentView';
import SignUpView from '../resources/views/account/auth/SignUpView';
import MerchantView from '../resources/views/client/MerchantView';
import HelpView from '../resources/views/client/HelpView';
import CheckoutView from '../resources/views/docs/checkoutView';
import WordpressView from '../resources/views/docs/wordpressView';
import ShopifyView from '../resources/views/docs/shopifyView';
import SettingsView from '../resources/views/docs/settingsView';

export default class ClientRoutes extends React.PureComponent {
	render() {
		return (
			<>
				<RouteHandler
					exact
					path={Routes.client.home}
					component={HomeView}
					middleware={['access:public']}
				/>
				<RouteHandler
					exact
					path={Routes.client.merchant}
					component={MerchantView}
					middleware={['access:public']}
				/>
				<RouteHandler
					exact
					path={Routes.client.works}
					component={HelpView}
					middleware={['access:public']}
				/>

				<RouteHandler
					exact
					path={Routes.account.dashboard}
					component={DashboardView}
					middleware={['access:auth']}
				/>
				<RouteHandler
					exact
					path={Routes.account.transaction}
					component={TransactionView}
					middleware={['access:auth']}
				/>
				<RouteHandler
					exact
					path={Routes.account.links}
					component={LinksView}
					middleware={['access:auth']}
				/>
				<RouteHandler
					exact
					path={Routes.account.settings}
					component={SettingsAccountView}
					middleware={['access:auth']}
				/>
				<RouteHandler
					exact
					path={Routes.account.profile}
					component={ProfileAccountView}
					middleware={['access:auth']}
				/>
				<RouteHandler
					exact
					path={Routes.account.login}
					component={LoginView}
					middleware={['access:guest']}
				/>
				<RouteHandler
					exact
					path={Routes.account.signup}
					component={SignUpView}
					middleware={['access:guest']}
				/>
				<RouteHandler
					exact
					path={Routes.account.password.forgot}
					component={ForgotPasswordView}
					middleware={['access:guest']}
				/>

				<RouteHandler
					exact
					path={Routes.merchant.payment}
					component={PaymentView}
					middleware={['access:public']}
				/>
				<RouteHandler
					exact
					path={Routes.merchant.checkout}
					component={CheckoutView}
					middleware={['access:public']}
				/>
				<RouteHandler
					exact
					path={Routes.merchant.wordpress}
					component={WordpressView}
					middleware={['access:public']}
				/>
				<RouteHandler
					exact
					path={Routes.merchant.shopify}
					component={ShopifyView}
					middleware={['access:public']}
				/>

				<RouteHandler
					exact
					path={Routes.client.payment}
					component={TransactionPaymentView}
					middleware={['access:public']}
				/>
			</>
		);
	}
}
