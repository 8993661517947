import React from 'react';
import Routes from '../config/routes';
import RouteHandler from '../core/helpers/RouteHandler';
import PaymentView from '../resources/views/docs/PaymentView';

export default class DocumentationRoutes extends React.PureComponent {
	render() {
		return (
			<>
				<RouteHandler
					exact
					path={Routes.docs.payment}
					component={PaymentView}
					middleware={['access:public']}
				/>
			</>
		);
	}
}
