import React from 'react';
import Navbar from '../component/navbar';
import USD from '../../../assets/images/united-states.png';
import EUR from '../../../assets/images/european-union.svg';
import Rwanda from '../../../assets/images/flag-rw.svg';
import Exports from '../../../assets/images/sending-to.png';
import Brand from '../../../assets/images/brand.svg';
import Check from '../../../assets/images/check-icon.svg';
import { Cancel } from '@material-ui/icons';
import { ViewUsers } from '../../../app/user/UserAction';
import { CreateTransfer } from '../../../app/link/LinkAction';

import Countries from '../../../assets/assets.js';
import { connect } from 'react-redux';

const currency = ['USD', 'EUR'];

class HomeView extends React.PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			merchant: {},
			data: {
				merchant: '',
				method: 'card',
				amount: '',
				currency: 'USD',
				title: 'transfer',
				description: 'Merchant payment'
			},
			message: '',
			error: false,
			code: '',

			status: null,
			loading: false,
		};
	}

	handleCurrency = c => {
		this.setState({ data: { ...this.state.data, currency: c } });
	};

	handleChange = async ({ target }) => {
		if (target.name === 'merchant') {
			this.setState({ loading: true, status: null });
		}

		if (target.name === 'merchant' && target.value.length === 9) {
			this.setState({ data: { ...this.state.data, merchant: target.value } });
			await this.props.ListUser(target.value.toUpperCase());

			this.setState({ loading: false });
		}

		this.setState({ code: '' });

		const data = { ...this.state.data };
		data[target.name] = target.value;

		this.setState({ data, loading: false });
	};

	handleClick = async e => {
		e.preventDefault();

		const { amount, currency } = this.state.data;
		await this.props.CreateTransfer(this.state.merchant?.id, this.state.data);
		// if(this.state.data?.merchant?.length !== 6 || this.state.data?.amount?.length < 1) return this.setState({error: true})
		// this.setState({error: false})
		// this.props.SendToMerchant(this.state.data);
	};

	componentWillReceiveProps = next => {
		if (next) {
			this.setState({ status: next.users.status, merchant: next.users.data });

			if (next.users?.country) {
				Countries.filter(
					c =>
						c.abbr === next.users.country?.toLowerCase() &&
						this.setState({ code: c.name })
				);
			}
		}
	};

	render() {
		const { data, error, code, merchant } = this.state;
		const { users } = this.props;

		console.log(merchant?.country?.toLowerCase(), code);

		return (
			<>
				<Navbar />
				<main className='fonts'>
					<section className='bg-image'>
						<div className='container'>
							<div className='row align-items-center'>
								<div className='col-xl-6 col-lg-6'>
									<h1 className='font-weight-bold text-white mb-4'>
										Pay & Get paid in Africa
									</h1>
									<div className='d-none mt-md-5 mt-4'>
										<img src={Brand} className='mr-4' alt='' />
										<div className='border-left pl-3'>
											<h5 className='text-white'>Great</h5>
											<h5 className='text-white'>44,989+ reviews</h5>
										</div>
									</div>
								</div>
								<div className='col-xl-5 offset-xl-1 col-lg-6 d-none d-lg-block'>
									<div className='card border-0 shadow rounded bg-light'>
										<div className='card-body'>
											{error && (
												<div className='alert alert-danger'>
													Please provide Merchant & Amount
												</div>
											)}
											<div className='card border-0 shadow-sm my-3'>
												<div className='card-body py-1'>
													<p className='mb-0'>You send</p>
													<div className='form-group row py-0 my-1 align-items-center'>
														<div className='col-8'>
															<input
																type='text'
																name='amount'
																placeholder='100.00'
																className='form-control border-0 font-weight-bold f-25 text-uppercase'
																value={data.amount}
																onChange={this.handleChange}
															/>
														</div>
														<div className='col-4 my-0'>
															<div class='dropdown'>
																<a
																	class='btn'
																	href='#'
																	id='dropdownMenuLink'
																	data-toggle='dropdown'
																>
																	<div className='d-flex justify-content-between align-items-center'>
																		{data.currency === 'USD' ? (
																			<img src={USD} className='mx-1' alt='' />
																		) : (
																			<img
																				src={EUR}
																				width='32'
																				className='mx-1'
																				alt=''
																			/>
																		)}
																		<span className='mx-1'>
																			{data.currency}
																		</span>
																		<i className='fa fa-chevron-down'></i>
																	</div>
																</a>
																<div
																	class='dropdown-menu'
																	aria-labelledby='dropdownMenuLink'
																>
																	{currency.map((c, i) => (
																		<a
																			key={i}
																			class='dropdown-item my-2'
																			href='#'
																			onClick={() => this.handleCurrency(c)}
																		>
																			<div className='media align-items-center'>
																				{c === 'EUR' ? (
																					<img src={EUR} width='32' />
																				) : (
																					<img src={USD} width='32' />
																				)}
																				<div className='media-body ml-3'>
																					{c}
																				</div>
																			</div>
																		</a>
																	))}
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
											<div className='media m-2'>
												{/* <img src={Exports} alt=""/> */}
												{this.state.data?.merchant?.length > 5 &&
												this.state.merchant === 422 ? (
													<Cancel style={{ color: '#DC3545' }} />
												) : (
													<img src={Exports} alt='' />
												)}
												<div className='media-body ml-2'>
													{/* <span>This card belongs to : Rwanda store Ltd</span> */}

													{this.state.data.merchant.length > 0 &&
													this.state.data.merchant.length < 9 ? (
														'Enter full merchant ID'
													) : this.state.loading ? (
														<span>This card belongs to : ...</span>
													) : this.state.status === 200 ? (
														<span>
															This card belongs to :{' '}
															{this.state.merchant?.business}
														</span>
													) : this.state.status === 404 ? (
														<span className='text-danger'>
															This merchant ID does not exist.{' '}
														</span>
													) : (
														<span>This card belongs to : Rwanda store Ltd</span>
													)}

													{/* {
                            !this.state.loading && !this.state.error ? <span className="text-danger">This merchant ID does not exist. </span> :
                            <span>This card belongs to : {this.state.merchant?.name ?? 'Rwanda store Ltd'}</span>
                          } */}
												</div>
											</div>
											<div className='card border-0 shadow-sm my-3'>
												<div className='card-body py-1'>
													<p className='mb-0'>Merchant ID</p>
													<div className='form-group row py-0 my-1 align-items-center'>
														<div className='col-8'>
															<input
																type='text'
																placeholder='EMC123456'
																name='merchant'
																value={data.merchant}
																onChange={this.handleChange}
																maxLength='9'
																className='form-control border-0 font-weight-bold f-25 text-uppercase'
															/>
														</div>
														<div className='col-4'>
															<div className='d-flex justify-content-between align-items-center'>
																{code.length > 1 ? (
																	Countries.map(
																		c =>
																			c.abbr ===
																				merchant?.country?.toLowerCase() && (
																				<img
																					src={c.image}
																					width='34'
																					className='mx-1'
																					alt=''
																				/>
																			)
																	)
																) : (
																	<img src={Rwanda} width='34' />
																)}
																{code.length > 1 ? (
																	Countries.map(
																		c =>
																			c.abbr ===
																				merchant?.country?.toLowerCase() && (
																				<span className='mx-1'>
																					{c.name.length > 9
																						? c.name.slice(0, 10) + '...'
																						: c.name}
																				</span>
																			)
																	)
																) : (
																	<span className='mx-1'>Rwanda</span>
																)}
															</div>
														</div>
													</div>
												</div>
											</div>
											<div className='form-group row mt-4'>
												<label className='col-5 col-form-label f-17'>
													Payment method:{' '}
												</label>
												<div className='col-7'>
													<select
														name='method'
														className='custom-select mr-sm-2'
														id='inlineFormCustomSelect'
														onChange={this.handleChange}
													>
														<option selected value='card'>Visa & Master cards</option>
														<option value='momo'>Mobile money</option>
													</select>
												</div>
											</div>
											<button
												className='btn btn-primary btn-block btn-lg'
												onClick={this.handleClick}
											>
												<b>Continue</b>
											</button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</section>
					<div className='d-lg-none d-block'>
						<div className='card border-0 shadow rounded bg-light'>
							<div className='card-body'>
								<div className='card border-0 shadow-sm my-3'>
									<div className='card-body py-1'>
										<p className='mb-0'>You send</p>
										<div className='form-group row py-0 my-1 align-items-center'>
											<div className='col-8'>
												<input
													type='text'
													placeholder='100.00'
													className='form-control border-0 font-weight-bold f-25 text-uppercase'
													name='amount'
													value={data.amount}
													onChange={this.handleChange}
												/>
											</div>
											<div className='col-4 my-0 pl-0'>
												<div class='dropdown'>
													<a
														class='btn'
														href='#'
														id='dropdownMenuLink'
														data-toggle='dropdown'
													>
														<div className='d-flex justify-content-between align-items-center'>
															{data.currency === 'USD' ? (
																<img src={USD} className='mx-1' alt='' />
															) : (
																<img
																	src={EUR}
																	width='32'
																	className='mx-1'
																	alt=''
																/>
															)}
															<span className='mx-1'>{data.currency}</span>
															<i className='fa fa-chevron-down'></i>
														</div>
													</a>
													<div
														class='dropdown-menu'
														aria-labelledby='dropdownMenuLink'
													>
														{currency.map((c, i) => (
															<a
																key={i}
																class='dropdown-item my-2'
																href='#'
																onClick={() => this.handleCurrency(c)}
															>
																<div className='media align-items-center'>
																	{c === 'EUR' ? (
																		<img src={EUR} width='32' />
																	) : (
																		<img src={USD} width='32' />
																	)}
																	<div className='media-body ml-3'>{c}</div>
																</div>
															</a>
														))}
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className='media mt-2'>
									{this.state.data?.merchant?.length > 5 &&
									this.state.merchant === 422 ? (
										<Cancel style={{ color: '#DC3545' }} />
									) : (
										<img src={Exports} alt='' />
									)}
									<div className='media-body ml-2'>
										{/* <span>This card belongs to : Rwanda store Ltd</span> */}
										{/* {message ? <span>{message}</span> : <span>This card belongs to : Rwanda store Ltd</span>} */}
										
										{this.state.data.merchant.length > 0 &&
											this.state.data.merchant.length < 9 ? (
												'Enter full merchant ID'
											) : this.state.loading ? (
												<span>This card belongs to : ...</span>
											) : this.state.status === 200 ? (
												<span>
													This card belongs to :{' '}
													{this.state.merchant?.business}
												</span>
											) : this.state.status === 404 ? (
												<span className='text-danger'>
													This merchant ID does not exist.{' '}
												</span>
											) : (
												<span>This card belongs to : Rwanda store Ltd</span>
											)}
									</div>
								</div>
								<div className='card border-0 shadow-sm my-3'>
									<div className='card-body py-1'>
										<p className='mb-0'>Merchant ID</p>
										<div className='form-group row py-0 my-1 align-items-center'>
											<div className='col-8'>
												<input
													type='text'
													placeholder='EMC123456'
													name='merchant'
													value={data.merchant}
													onChange={this.handleChange}
													className='form-control border-0 font-weight-bold f-25 text-uppercase'
												/>
											</div>
											<div className='col-4 pl-0'>
												<div className='d-flex justify-content-between align-items-center'>
													{code.length > 1 ? (
														Countries.map(
															c =>
																c.abbr === merchant?.country?.toLowerCase() && (
																	<img
																		src={c.image}
																		width='34'
																		className='mx-1'
																		alt=''
																	/>
																)
														)
													) : (
														<img src={Rwanda} width='34' />
													)}
													{code.length > 1 ? (
														Countries.map(
															c =>
																c.abbr === merchant?.country?.toLowerCase() && (
																	<span className='mx-1'>{c.name}</span>
																)
														)
													) : (
														<span className='mx-1'>Rwanda</span>
													)}
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className='form-group row mt-4'>
									<label className='col-5 col-form-label f-17'>
										Payment method:{' '}
									</label>
									<div className='col-7'>
										<select
											name='method'
											className='custom-select mr-sm-2'
											id='inlineFormCustomSelect'
											onChange={this.handleChange}
										>
											<option selected value='card'>Visa & Master cards</option>
											<option value='momo'>Mobile Money</option>
										</select>
									</div>
								</div>
								{error && (
									<div className='alert alert-danger'>
										Please provide Merchant & Amount
									</div>
								)}
								<button
									className='btn btn-primary btn-block btn-lg'
									onClick={this.handleClick}
								>
									<b>Continue</b>
								</button>
							</div>
						</div>
					</div>
					<div className='bg-primary'>
						<div className='container'>
							<div className='row text-center'>
								<div className='col-md-4'>
									<img src={Check} alt='' />
									<h4 className='text-white'>Visa Card</h4>
								</div>
								<div className='col-md-4'>
									<img src={Check} alt='' />
									<h4 className='text-white'>MasterCard</h4>
								</div>
								<div className='col-md-4'>
									<img src={Check} alt='' />
									<h4 className='text-white'>Mobile Money</h4>
								</div>
							</div>
						</div>
					</div>
				</main>
			</>
		);
	}
}

const mapStateToProps = state => ({
	users: state.UserReducer.users,
});

const mapDispatchToProps = dispatch => ({
	ListUser: id => dispatch(ViewUsers(id)),
	CreateTransfer: (id, payload) => dispatch(CreateTransfer(id, payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(HomeView);
