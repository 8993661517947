import React from "react";
import { Link } from "react-router-dom";
import { Container, Button, Image } from "react-bootstrap";
import Routes from '../../../config/routes';
import Util from '../../../core/helpers/Util';
import Avatar from '../../../assets/images/avatar.jpg';
import './index.css';
import helper from '../../../core/helpers/helper';
import Shopify from '../../../assets/images/shopify.svg'

export default class DocsBar extends React.PureComponent {
  handleCloseSidebar = () => {
    const { handleSidebar } = this.props;
    handleSidebar(false);
  }

  render() {
    const { mobileSidebar } = this.props;

    return (
      <div className='sidebar-content'>
        {
          mobileSidebar && <Container className='sidebar-profile mb-4'>
            <div class="dropdown">
            <a class='font-regular nav-link dropdown-toggle'  to={Routes.client.home} id="navbarDropdownMenuLink" role="button" data-toggle="dropdown">
              <Image src={Avatar} className='navbar-avatar img-fluid' alt='Full Name' />
              <small className='navbar-user'>
                My Account
              </small>
            </a>
            <div class="dropdown-menu border-0 shadow" aria-labelledby="navbarDropdownMenuLink">
              <Link class="dropdown-item" to={Routes.account.login}><small className="font-bold space-0 text-muted"> Login</small></Link>
              <Link class="dropdown-item" to={Routes.account.signup} onClick={this.handleLogOut}><small className="font-bold space-0 text-muted"> Register</small></Link>
            </div>
          </div>

            <Button variant='default' className='navbar-close-collapse' onClick={this.handleCloseSidebar}>
              <i className='fas fa-times'></i>
            </Button>
          </Container>
        }

        <div className='sidebar-menu-box'>
          <ul className="pt-md-5">
            <li>
              <Link to={Util.route(Routes.merchant.payment)} className={helper.activeMenu(Routes.merchant.payment)}>
                <p><span className='sidebar-menu-icon'><i className='fas fa-link'></i></span> Pay Links</p>
              </Link>
            </li>

            <li>
              <Link to={Util.route(Routes.merchant.checkout)}  className={helper.activeMenu(Routes.merchant.checkout)}>
                <span className='sidebar-menu-icon'><i className='fas fa-flag-checkered'></i></span>  Checkout API
              </Link>
            </li>
            
            <li>
              <Link to={Util.route(Routes.merchant.wordpress)}  className={helper.activeMenu(Routes.merchant.wordpress)}>
                <span className='sidebar-menu-icon'><i className='fab fa-wordpress'></i></span>  Wordpress
              </Link>
            </li>
            
            <li>
              <Link to={Util.route(Routes.merchant.shopify)}  className={helper.activeMenu(Routes.merchant.shopify)}>
                <span className='sidebar-menu-icon'><img src={Shopify} className="img-fluid" alt=""/></span>  Shopify
              </Link>
            </li>
          </ul>
        </div>
        
        {/* <div className='sidebar-merchant mt-4'>
          <ul>
            <li>
              <Link to={Util.route(Routes.merchant.settings)} className={helper.activeMenu(Routes.merchant.settings)}>
                <p className="space-0"><span className='sidebar-menu-icon'><i className='fas fa-cog'></i></span> Settings</p>
              </Link>
            </li>
          </ul>
          <div className='sidebar-merchant-box bg-grey px-3 py-2 color-black'>
            <p><small className='font-bold text-muted'>Account </small> <span className='float-right badge bg-info py-1 px-2 color-white'>EMC</span></p>
            <h6 className='font-bold text-primary'>RW1212</h6>
          </div>
        </div> */}
      </div>
    );
  }
}
