import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { connect, useDispatch, useSelector } from 'react-redux';
import Arrow from '../../../../assets/images/arrow.svg';
import Card from '../../../../assets/images/card.svg';
import Credit from '../../../../assets/images/credit-card.svg';
import UserLayout from '../../../layouts/UserLayout';
import { ListTransaction } from '../../../../app/transaction/TransactionAction';

const TransactionView = () => {
	const search = useState('');
	const transaction = useSelector(
		state => state.TransactionReducer.transaction
	);

	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(ListTransaction());
	}, [dispatch]);

	console.log('data', transaction);

	return (
		<UserLayout>
			<div className='row'>
				<div className='col-lg-8'>
					<h4 className='font-weight-bold d-md-none d-inline m-3'>
						Transaction List
					</h4>
					<div className='d-flex justify-content-between align-items-center'>
						<h4 className='font-weight-bold d-none d-md-inline'>
							Transaction List
						</h4>
						<div className='col-md-5 my-3 my-md-0'>
							<div class='input-group mb-3 border-0'>
								<input
									type='text'
									class='form-control border-0 bg-white'
									placeholder='Search transaction'
								/>
								<span
									class='input-group-text border-0 bg-light rounded-0'
									id='basic-addon2'
								>
									<i className='fa fa-search'></i>
								</span>
							</div>
						</div>
					</div>
					<div className='table-responsive'>
						<table class='table table-borderless'>
							<thead>
								<tr>
									<th scope='col'>#</th>
									<th scope='col'>Name</th>
									<th scope='col'>Type</th>
									<th scope='col'>Mode</th>
									<th scope='col'>Amount</th>
									<th scope='col'>Date / Time</th>
								</tr>
							</thead>
							<tbody>
								{transaction.map((value, key) => (
									<tr className='table-light shadow-sm'>
										<td scope='row'>{key + 1}</td>
										<td>{
												value.type === 'in' ? value.payload.name : value.payload.description
											}</td>
										<td scope='row'>
											{
												value.type === 'in' ? 'Received' : 'Sent'
											}
										</td>
										<td>MoMo Rwanda</td>
										<td>
											{
												value.type === 'in' ? <span className='text-success'>
												+ {`${value.payload.amount} ${value.payload.currency}`}
											</span> : <span className='text-danger'>
												- {`${value.payload.amount} ${value.payload.currency}`}
											</span>
											}
										</td>
										<td>{moment(value.created_at).format('MMMM Do HH:mm')}</td>
									</tr>
								))}

								{/* <div className='my-1'></div>
									
									<tr className='table-light shadow-sm'>
										<td scope='row'>2</td>
										<td>Honore NIYITEGEKA</td>
										<td>RWF</td>
										<td>Wireframing</td>
										<td>
											<span className='text-warning'>- 8,000</span>
										</td>
										<td>24/5/2021</td>
									</tr> */}
							</tbody>
						</table>
					</div>
				</div>
				<div className='col-lg-4 my-3 '>
					<h6>
						<b>Spendings</b>
					</h6>
					<div className='card shadow-sm border-0 my-2'>
						<div className='card-body py-2'>
							<div className='media align-items-center'>
								<div className='card border-0 bg-lighter'>
									<div className='card-body px-2 py-1'>
										<img src={Arrow} className='img-fluid' alt='' />
									</div>
								</div>
								<div className='media-body ml-2'>
									<p className='my-1'>
										<small>
											<b>Avg level</b>
										</small>
									</p>
									<p className='my-1'>
										<small className='text-muted'>
											<strong>10%</strong> less than the previous period
										</small>
									</p>
								</div>
							</div>
						</div>
					</div>
					<div className='card shadow-sm border-0 my-2'>
						<div className='card-body py-2'>
							<div className='media align-items-center'>
								<div className='card border-0 bg-lighter'>
									<div className='card-body px-2 py-1'>
										<img src={Card} className='img-fluid' alt='' />
									</div>
								</div>
								<div className='media-body ml-2'>
									<p className='my-1'>
										<small>
											<b>Income Budget</b>
										</small>
									</p>
									<p className='my-1'>
										<small className='text-muted'>
											<strong>$130</strong> is what you have now
										</small>
									</p>
								</div>
							</div>
						</div>
					</div>
					<div className='card shadow-sm border-0 my-2'>
						<div className='card-body py-2'>
							<div className='media align-items-center'>
								<div className='card border-0 bg-lighter'>
									<div className='card-body px-2 py-1'>
										<img src={Credit} className='img-fluid' alt='' />
									</div>
								</div>
								<div className='media-body ml-2'>
									<p className='my-1'>
										<small>
											<b>Withdraws Budget</b>
										</small>
									</p>
									<p className='my-1'>
										<small className='text-muted'>
											<strong>$130</strong> were spent from{' '}
											<strong>$1,400</strong>
										</small>
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</UserLayout>
	);
};

export default TransactionView;
