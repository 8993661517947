import React, { useState } from 'react';
import Logo from '../../../assets/images/logo.png';
import LogoDark from '../../../assets/images/logo-dark-small.png';
import SmallLogo from '../../../assets/images/logo-small.png'
import { Link } from 'react-router-dom';
import Routes from '../../../config/routes';

const Navbar = ({theme}) => {
  const [navbar, setNavbar] = useState(false);

  const changeBackground = () => {
		if (window.scrollY >= 50) return setNavbar(true);

		return setNavbar(false);
	};

	window.addEventListener('scroll', changeBackground);

  return (  
    <nav className={navbar
      ? 'navbar navbar-expand-md navbar-light fixed-top active-menu shadow'
      : 'navbar navbar-expand-md fixed-top navbar-light'}>
      <div className={theme ? `container` : `container-fluid`}>
        <Link to="/" className="navbar-brand">{navbar ? <img src={LogoDark} alt="" /> : <> <img src={Logo} className="d-none d-md-inline" alt=""/> <img src={SmallLogo} className="d-inline d-md-none" alt=""/></>}</Link>
        <button
          className='navbar-toggler'
          type='button'
          data-toggle='collapse'
          data-target='#navbarNav'
          aria-controls='navbarSupportedContent'
          aria-expanded='false'
          aria-label='Toggle navigation'
        >
          <i
            className={
              theme ? `fa fa-bars text-black-50 fa-2x` : navbar ? 'fa fa-bars text-black-50 fa-2x' : 'fa fa-bars text-white fa-2x'
            }
          ></i>
        </button>
        <ul className="navbar-nav flex-row d-md-none align-items-center">
          <li className="nav-item mx-md-2 d-none d-md-inline">
            <Link className={theme ? "nav-link mt-2" : navbar ? "nav-link mt-2" : "nav-links mt-2"} to={Routes.client.works}>Help</Link>
          </li>
          <li className="nav-item mx-md-2">
            <Link to={Routes.account.login} className={theme ? "nav-link mt-2" : navbar ? "nav-link mt-2" : "nav-links mt-2"}>Login</Link>
          </li>
          <li className="nav-item mx-md-2">
            <Link class={`btn ${theme ? `btn-primary pt-2` : navbar ? `btn-dark pt-2` : `btn-outline-light`} btn-sm mt-2 px-3`} to={Routes.account.signup}><b> Sign up</b></Link>
          </li>
        </ul>
        
        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav mr-auto d-md-flex justify-content-md-between w-100">
            <li className="nav-item mx-md-2 ml-lg-4">
            </li>
            <ul className="navbar-nav d-flex">
              <li className="nav-item mx-md-2 d-none d-md-inline">
                <Link className={theme ? "nav-link" : navbar ? "nav-link" : "nav-links"} to={Routes.client.merchant}>Merchant account</Link>
              </li>
              <li className="nav-item mx-md-2 d-block d-md-none">
                <Link className="text-dark" to={Routes.client.merchant}>Merchant account</Link>
              </li>
            </ul>
          </ul>
        </div>

        <ul className="navbar-nav flex-row d-none d-md-flex align-items-center">
          <li className="nav-item mx-md-2">
            <Link className={theme ? "nav-link" : navbar ? "nav-link" : "nav-links"} to={Routes.client.works}>Help</Link>
          </li>
          <li className="nav-item mx-md-2">
            <Link to={Routes.account.login} className={theme ? "nav-link" : navbar ? "nav-link" : "nav-links"}>Login</Link>
          </li>
          <li className="nav-item mx-md-2">
            <Link class={`btn ${theme ? "btn-primary" : navbar ? `btn-dark` : `btn-outline-light`} btn-sm px-3`} to={Routes.account.signup}>Register</Link>
          </li>
        </ul>
      </div>
    </nav>
  );
}
 
export default Navbar;
